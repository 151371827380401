import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { InventoryModule } from "@core/app/inventory/inventory.module";
import { LayoutModule } from "@core/app/layout/layout.module";
import { Map2Module } from "@core/app/map2/map2.module";
import { SharedModule } from "@core/app/shared/shared.module";
import { SliderModule } from "@core/app/slider/slider.module";
import { HallsHomeCardsComponent } from "./components/halls-home-cards/halls-home-cards.component";
import { HallsLocationsComponent } from "./components/halls-locations/halls-locations.component";
import { HallsSearchFilterComponent } from "./components/halls-search-filter/halls-search-filter.component";
import { HallsSidebarComponent } from "./components/halls-sidebar/halls-sidebar.component";
import { HallsAutocompleteComponent } from "./components/halls-autocomplete/halls-automplete.component";
import { HallsLocationsMapComponent } from "./components/halls-locations-map/halls-locations-map.component";
import { HallsMfrsComponent } from "./components/halls-mfrs/halls-mfrs.component";
import { HallsWhereToBoatComponent } from "./components/halls-where-to-boat/halls-where-to-boat.component";
import { HallsCareerCardsComponent } from "./components/halls-career-cards/halls-career-cards.component";

@NgModule({
	declarations: [
		HallsSidebarComponent,
		HallsSearchFilterComponent,
		HallsHomeCardsComponent,
		HallsLocationsComponent,
		HallsAutocompleteComponent,
		HallsLocationsMapComponent,
		HallsMfrsComponent,
		HallsWhereToBoatComponent,
		HallsCareerCardsComponent,
	],
	imports: [
		CommonModule,
		FontAwesomeModule,
		InventoryModule,
		NgbModule,
		FormsModule,
		RouterModule,
		Map2Module,
		SliderModule,
		LayoutModule,
		SharedModule,
	],
	exports: [
		HallsSidebarComponent,
		HallsSearchFilterComponent,
		HallsHomeCardsComponent,
		HallsLocationsComponent,
		HallsAutocompleteComponent,
		HallsLocationsMapComponent,
		HallsMfrsComponent,
		HallsWhereToBoatComponent,
		HallsCareerCardsComponent,
	],
})
export class HallsModule {}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MenuModule } from "@core/app/menu/menu.module";
import { SharedModule } from "@core/app/shared/shared.module";
import { AccountModule } from "../account/account.module";
import { CateringModule } from "../catering/catering.module";
import { HeaderComponent } from "./components/header.component";

@NgModule({
	declarations: [HeaderComponent],
	imports: [CommonModule, SharedModule, MenuModule, AccountModule, CateringModule],
	exports: [HeaderComponent],
})
export class HeaderModule {}

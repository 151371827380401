import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReviewCardComponent } from "./components/review-card/review-card.component";
import { StarListingComponent } from "./components/star-listing/star-listing.component";
import { ImageModule } from "@core/app/image/image.module";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { ReviewsSlideshowComponent } from "./components/review-slideshow/reviews-slideshow.component";
import { ReviewRowComponent } from "./components/review-row/review-row.component";
import { SliderModule } from "../slider/slider.module";
import { RatingsRowComponent } from "./components/ratings-row/ratings-row.component";

@NgModule({
	declarations: [
		ReviewCardComponent,
		StarListingComponent,
		ReviewsSlideshowComponent,
		ReviewRowComponent,
		RatingsRowComponent,
	],
	imports: [CommonModule, FontAwesomeModule, ImageModule, SliderModule],
	exports: [ReviewsSlideshowComponent, ReviewRowComponent, StarListingComponent, RatingsRowComponent],
})
export class ReviewsModule {}

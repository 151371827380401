import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { TelPipe } from "@core/app/pipes/tel.pipe";
import { Image2Component } from "./components/image2/image2.component";
import { ImagePipe } from "./pipes/image.pipe";

@NgModule({
	declarations: [TelPipe, Image2Component, ImagePipe],
	imports: [CommonModule, RouterModule],
	exports: [TelPipe, Image2Component, ImagePipe],
})
export class ImageModule {}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CalendarDayDirective } from "./components/calendar-day.directive";
import { CalendarItemDirective } from "./components/calendar-item.directive";
import { CalendarComponent } from "./components/calendar/calendar.component";

@NgModule({
	declarations: [CalendarComponent, CalendarDayDirective, CalendarItemDirective],
	imports: [CommonModule, NgbModule, ReactiveFormsModule, FontAwesomeModule],
	exports: [CalendarComponent, CalendarDayDirective, CalendarItemDirective],
})
export class CalendarModule {}

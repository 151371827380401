import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ImageModule } from "../image/image.module";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { HallsListingCardComponent } from "./components/halls-listing-card/halls-listing-card.component";
import { ListingGridComponent } from "./components/listing-grid/listing-grid.component";
import { HallsHomePageInventoryComponent } from "./components/halls-home-page-inventory/halls-home-page-inventory.component";
import { ListingSlideshowComponent } from "./components/listing-slideshow/listing-slideshow.component";
import { SliderModule } from "../slider/slider.module";
import { RouterModule } from "@angular/router";
import { FormModule } from "../form/form.module";
import { ListingCrumbsComponent } from "./components/listing-crumbs/listing-crumbs.component";
import { LayoutModule } from "../layout/layout.module";
import { PanotourComponent } from "./components/panotour/panotour.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ListingColorsComponent } from "./components/listing-colors/listing-colors.component";

@NgModule({
	declarations: [
		HallsHomePageInventoryComponent,
		HallsListingCardComponent,
		ListingGridComponent,
		ListingSlideshowComponent,
		ListingCrumbsComponent,
		PanotourComponent,
		ListingColorsComponent,
	],
	imports: [
		CommonModule,
		FontAwesomeModule,
		FormModule,
		ImageModule,
		NgbModule,
		RouterModule,
		SliderModule,
		LayoutModule,
	],
	exports: [
		ListingGridComponent,
		ListingSlideshowComponent,
		HallsHomePageInventoryComponent,
		ListingCrumbsComponent,
		PanotourComponent,
		ListingColorsComponent,
	],
})
export class ListingModule {}

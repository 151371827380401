import { HttpClient } from "@angular/common/http";
import { ChangeDetectionStrategy, Component, ElementRef, ViewChild, Output, EventEmitter, Input } from "@angular/core";
import { UserService } from "@core/app/services/user.service";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, throwError } from "rxjs";
import { switchMap } from "rxjs/operators";
import { GTMService } from "@core/app/services/gtm.service";

@Component({
	selector: "cm-account",
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<div class="btn-group btn-group-toggle w-100 mb-sm-5 mb-3" ngbRadioGroup [(ngModel)]="state">
			<label
				ngbButtonLabel
				class="btn btn-sm {{ state === LoginState.Login ? 'btn-primary' : 'btn-outline-primary' }}"
				style="border-top-left-radius:10px; border-bottom-left-radius:10px;"
			>
				<input ngbButton type="radio" [value]="LoginState.Login" /> Log In
			</label>
			<label
				ngbButtonLabel
				class="btn btn-sm {{ state === LoginState.Register ? 'btn-primary' : 'btn-outline-primary' }}"
				style="border-top-right-radius:10px; border-bottom-right-radius:10px;"
			>
				<input ngbButton type="radio" [value]="LoginState.Register" /> Sign Up
			</label>
		</div>

		<form #form class="text-center" (ngSubmit)="submit()">
			<div *ngIf="state === LoginState.Register" class="form-group mb-sm-5 mb-3">
				<cm-field-text type="text" placeholder="First Name" required [(value)]="firstName"></cm-field-text>
			</div>
			<div *ngIf="state === LoginState.Register" class="form-group mb-sm-5 mb-3">
				<cm-field-text type="text" placeholder="Last Name" required [(value)]="lastName"></cm-field-text>
			</div>
			<div class="form-group mb-sm-5 mb-3">
				<cm-field-text placeholder="Username" required [(value)]="username"></cm-field-text>
			</div>
			<div *ngIf="state === LoginState.Register" class="form-group mb-sm-5 mb-3">
				<cm-field-text type="email" placeholder="Email" required [(value)]="email"></cm-field-text>
			</div>
			<div *ngIf="state !== LoginState.ForgotPass" class="form-group mb-sm-5 mb-3">
				<cm-field-text type="password" placeholder="Password" required [(value)]="password"></cm-field-text>
			</div>
			<a
				*ngIf="state === LoginState.Login"
				href="javascript:void(0)"
				class="d-block mb-sm-5 mb-3 forgot-pass"
				(click)="state = LoginState.ForgotPass"
			>
				Forgot Password?
			</a>
			<div *ngIf="state === LoginState.Register" class="form-group mb-sm-5 mb-3">
				<cm-field-text
					type="password"
					placeholder="Confirm Password"
					required
					[(value)]="passwordConfirm"
				></cm-field-text>
			</div>
			<div class="text-danger mb-3">{{ errorBS | async }}</div>
			<button [ngSwitch]="state" class="btn btn-primary px-5" [disabled]="loading">
				<ng-container *ngSwitchCase="LoginState.Login">Sign In</ng-container>
				<ng-container *ngSwitchCase="LoginState.Register">Sign Up</ng-container>
				<ng-container *ngSwitchCase="LoginState.ForgotPass">Send Email</ng-container>
			</button>
		</form>
	`,
	styles: [
		`
			:host {
				display: block;
			}

			:host ::ng-deep .form-control {
				text-align: center;
			}

			:host ::ng-deep .form-control::placeholder {
				text-transform: uppercase;
				letter-spacing: 3px;
				color: #2e393f;
				font-size: 1.1rem;
			}

			.forgot-pass {
				color: #2e393f;
				opacity: 0.7;
				text-transform: uppercase;
				letter-spacing: 2px;
			}

			:host ::ng-deep .was-validated .form-control:invalid,
			:host ::ng-deep .form-control.is-invalid,
			:host ::ng-deep .was-validated .form-control:valid,
			:host ::ng-deep .form-control.is-valid {
				padding-right: 1rem !important;
			}
		`,
	],
})
export class AccountComponent {
	@Output() loggedIn = new EventEmitter<void>();
	@Input() state = LoginState.Login;

	@ViewChild("form", { static: true }) form!: ElementRef<HTMLFormElement>;

	LoginState = LoginState;

	errorBS = new BehaviorSubject("");
	loading = false;
	username = "";
	firstName = "";
	lastName = "";
	email = "";
	password = "";
	passwordConfirm = "";

	constructor(
		private http: HttpClient,
		private notification: ToastrService,
		private userService: UserService,
		private gtmService: GTMService,
	) {}

	submit() {
		if (!this.form.nativeElement.reportValidity()) {
			return;
		}

		if (this.state === LoginState.Login) {
			this.login();
		} else if (this.state === LoginState.Register) {
			this.createAccount();
		} else {
			this.forgotPassword();
		}
	}

	forgotPassword() {
		this.loading = true;
		this.errorBS.next("");

		this.http.post("api/user/forgotPassword", { userName: this.username }).subscribe((res: any) => {
			this.loading = false;
			if (res.success) {
				this.notification.success(res.message);
				this.loggedIn.next();
			} else {
				this.notification.error(res.message);
			}
		});
	}

	login() {
		this.loading = true;
		this.errorBS.next("");

		this.userService.logIn(this.username, this.password, true).subscribe({
			next: () => {
				this.loggedIn.next();
				this.loading = false;
			},
			error: (res) => {
				this.errorBS.next(res.error.message);
				this.loading = false;
			},
		});
	}

	createAccount() {
		const user = {
			userLogin: this.username,
			email: this.email,
			password: this.password,
			confirmPassword: this.passwordConfirm,
			customerRecord: true,
			firstName: this.firstName,
			lastName: this.lastName,
		};
		this.http
			.post("api/user/create", user)
			.pipe(
				switchMap((res: any) => {
					if (res.success) {
						this.notification.success("Account created successfully.");
						return this.userService.logIn(this.username, this.password, true);
					} else {
						this.notification.error(res.message);
						return throwError(res);
					}
				}),
			)
			.subscribe({
				next: () => {
					this.loggedIn.next();
					this.loading = false;
					this.gtmService.track("User Account Created", "success", this.username);
				},
				error: (res) => {
					this.notification.error(res.message);
					this.loading = false;
				},
			});
	}
}

enum LoginState {
	Login,
	Register,
	ForgotPass,
}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { BootstrapModule } from "../bootstrap/bootstrap.module";
import { ImageModule } from "../image/image.module";
import { BrandFloorPlansComponent } from "./components/floor-plans/floor-plans.component";
import { BrandSummaryComponent } from "./components/summary/summary.component";
import { BrandBrochureComponent } from "./components/brochure/brochure.component";
import { FormModule } from "../form/form.module";

@NgModule({
	declarations: [BrandFloorPlansComponent, BrandSummaryComponent, BrandBrochureComponent],
	imports: [BootstrapModule, CommonModule, FontAwesomeModule, FormModule, ImageModule, RouterModule],
	exports: [BrandFloorPlansComponent, BrandSummaryComponent, BrandBrochureComponent],
})
export class BrandModule {}

import { Pipe, PipeTransform } from "@angular/core";
import { iter } from "shared/common";

@Pipe({ name: "keyval" })
export class KeyValPipe implements PipeTransform {
	transform<K, V>(value: Iterable<[K, V]>): { key: K; val: V }[] {
		return iter(value)
			.map(([key, val]) => ({ key, val }))
			.toArray();
	}
}

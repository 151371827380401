import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { BootstrapModule } from "../bootstrap/bootstrap.module";
import { ImageModule } from "../image/image.module";
import { ModelNumSummaryComponent } from "./components/summary/summary.component";
import { BrandModule } from "../brand/brand.module";

@NgModule({
	declarations: [ModelNumSummaryComponent],
	imports: [BootstrapModule, BrandModule, CommonModule, FontAwesomeModule, ImageModule, RouterModule],
	exports: [ModelNumSummaryComponent],
})
export class ModelNumModule {}

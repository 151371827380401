import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { VideoComponent } from "./components/video/video.component";

@NgModule({
	declarations: [VideoComponent],
	imports: [CommonModule],
	exports: [VideoComponent],
})
export class VideoModule {}

import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	Output,
	ViewChild,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { range } from "shared/common";
import { mod } from "@common/util";
import { SlideDirective } from "../slide.directive";

@Component({
	selector: "cm-slider-impl-translate",
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<div
			class="d-flex h-100 slider-wrapper"
			#translateSlide
			[style.transform]="translateX()"
			[ngClass]="{ smooth: !isDown }"
		>
			<div
				*ngFor="let slide of slideSlice(); index as i; trackBy: slideTrackBy"
				class="flex-shrink-0 limit-x h-100 slide {{ slide.index }}"
				(touchstart)="grab($event)"
				(touchmove)="move($event)"
				(mousedown)="grab($event)"
				(mousemove)="move($event)"
				(mouseleave)="reset($event)"
				[ngClass]="{ active: slide.index === slideIndex, animated: i < preload * 2 }"
				[style.width.%]="slide.width"
			>
				<div class="d-flex justify-content-center align-items-center h-100">
					<div
						*ngIf="slide.slide"
						class="{{ fullWidth ? 'w-100' : 'mw-100' }}"
						[ngClass]="{ 'h-100': fullHeight }"
					>
						<ng-container
							*ngTemplateOutlet="slide.slide!.templateRef; context: { index: slide.index }"
						></ng-container>
					</div>
				</div>
			</div>
		</div>
	`,
	styles: [
		`
			:host {
				display: block;
				height: 100%;
			}
			.limit-x {
				overflow-x: hidden;
			}
			.slide.animated {
				transition: width 0.3s;
			}
			.slider-wrapper.smooth {
				transition: transform 0.3s;
			}
			.slider-wrapper {
				align-items: center;
			}
		`,
	],
})
export class SliderImplTranslateComponent implements AfterViewInit {
	@Input() center: boolean = false;
	@Input() fullWidth: boolean = false;
	@Input() fullHeight: boolean = false;
	@Input() loop: boolean = false;
	@Input() preload!: number;
	@Input() slides!: SlideDirective[];
	@Input() slideIndex: number = 0;
	@Input() inView: number = 1;
	@Input() draggable: boolean = true;
	@Output() slideChange: EventEmitter<number> = new EventEmitter();
	@ViewChild("translateSlide", { static: false }) translateSlide!: ElementRef<HTMLDivElement>;

	isDown = false;
	startX = 0;
	scrollLeft = 0;

	constructor(private sanitizer: DomSanitizer) {}

	ngAfterViewInit(): void {
		this.translateSlide.nativeElement.addEventListener(
			"click",
			(event) => {
				this.release(event);
				return true;
			},
			{
				capture: true,
			},
		);
		this.translateSlide.nativeElement.addEventListener(
			"touchend",
			(event) => {
				this.release(event);
				return true;
			},
			{
				capture: true,
			},
		);
	}

	release(event: Event) {
		if (this.scrollLeft > 100) {
			this.slideChange.emit(-1);
			event.stopPropagation();
		} else if (this.scrollLeft < -100) {
			this.slideChange.emit(1);
			event.stopPropagation();
		}

		this.scrollLeft = 0;
		this.isDown = false;
	}

	reset(event: Event) {
		if (this.isDown) {
			this.isDown = false;
			this.scrollLeft = 0;
		}
	}

	grab(event: any) {
		if (this.draggable) {
			this.isDown = true;
			this.startX = event.pageX || event.touches[0].pageX;
		}
	}

	move(event: any) {
		if (this.isDown && this.draggable) {
			event.preventDefault();
			const x = event.pageX || event.touches[0].pageX;
			this.scrollLeft = x - this.startX;
		}
	}

	slideSlice() {
		let start = this.slideIndex;
		if (this.center) {
			start -= Math.floor(this.inView / 2);
		}
		if (!this.loop) {
			start = Math.max(0, Math.min(start, this.slides.length - this.inView));
		}

		if (this.loop || this.slides.length > this.inView) {
			start -= this.preload;
		}

		const end = start + this.inView + this.preload * 2;

		const width = 100 / this.inView;
		const view = range(start, end).map((index) => {
			const retNull = !this.loop && (index < 0 || index >= this.slides.length);
			return {
				index,
				slide: retNull ? null : this.slides[mod(index, this.slides.length)],
				width,
			};
		});
		const before0 = range(start - this.preload * 2, start - this.preload).map((index) => ({
			index,
			slide: null,
			width: 0,
		}));
		const before = range(start - this.preload, start).map((index) => ({ index, slide: null, width }));
		const after = range(end, end + this.preload).map((index) => ({ index, slide: null, width }));
		const after0 = range(end + this.preload, end + this.preload * 2).map((index) => ({
			index,
			slide: null,
			width: 0,
		}));
		if (this.loop || this.slides.length > this.inView) {
			return [...before0, ...before, ...view, ...after, ...after0];
		} else {
			return [...view];
		}
	}

	translateX() {
		const offset = this.loop || this.slides.length > this.inView ? ((this.preload * 2) / this.inView) * 100 : 0;
		return this.sanitizer.bypassSecurityTrustStyle(`translateX(calc(-${offset}% + ${this.scrollLeft}px))`);
	}

	slideTrackBy(_index: number, item: { slide: SlideDirective; index: number }) {
		return item.index;
	}
}

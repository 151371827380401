import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FooterComponent } from "./components/footer.component";
import { SharedModule } from "@core/app/shared/shared.module";

@NgModule({
	declarations: [FooterComponent],
	imports: [CommonModule, SharedModule],
	exports: [FooterComponent],
})
export class FooterModule {}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { combineLatest, Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { iter, tuple } from "shared/common";

@Injectable({ providedIn: "root" })
export class CateringMenuService {
	items$ = this.http.post("/api/statement/GetAllOffers", {}).pipe(
		map((res: any) => res.results),
		shareReplay(1),
	);

	private itemMapTree$ = this.items$.pipe(
		map((items) => {
			const itemMap = iter(items)
				.map((item: any) => tuple(item.offerid, { ...item, children: [] }))
				.toMap();

			const roots: any[] = [];
			for (const item of itemMap.values()) {
				const list = (() => {
					if (item.pkg_offerid) {
						const parent = itemMap.get(item.pkg_offerid);
						if (parent) {
							return parent.children;
						} else {
							return null;
						}
					} else {
						return roots;
					}
				})();
				if (list) {
					list.push(item);
				}
			}

			return tuple(itemMap, roots);
		}),
		shareReplay(1),
	);

	allCats$: Observable<any> = this.http.post("/api/statement/GetAllSiteOfferCats", {}).pipe(
		map((res: any) => res.results),
		shareReplay(1),
	);

	allCatsMap$: Observable<any> = this.allCats$.pipe(
		map((res: any) => {
			const catMap = new Map<string, string>();
			for (const row of res) {
				catMap.set(row.offer_cat, row);
			}
			return catMap;
		}),
		shareReplay(1),
	);

	allCatDescs$: Observable<any> = this.allCats$.pipe(
		map((res: any) => {
			const catMap = new Map<string, string>();
			for (const row of res) {
				catMap.set(row.offer_cat, row.offer_cat_desc);
			}
			return catMap;
		}),
		shareReplay(1),
	);

	catTree$ = this.allCats$.pipe(
		map((res: any) => {
			const catMap = new Map<number, any>();
			for (const row of res) {
				catMap.set(row.site_offer_catid, { ...row, children: [] });
			}
			const roots = [];
			for (const row of catMap.values()) {
				const parent = catMap.get(row.site_offer_cat_ofid);
				if (parent) {
					parent.children.push(row);
				} else {
					roots.push(row);
				}
			}
			return iter(roots)
				.map((root) => tuple(root.offer_cat, root))
				.toMap();
		}),
		shareReplay(1),
	);

	itemMap$ = this.itemMapTree$.pipe(map(([x]) => x));
	itemTree$ = this.itemMapTree$.pipe(map(([_, x]) => x));

	itemsByCat$ = combineLatest(this.items$, this.allCats$).pipe(
		map(([rows, allCats]) => {
			const ret = new Map<string, any>();
			for (const cat of allCats) {
				ret.set(cat.offer_cat, []);
			}

			for (const row of rows) {
				ret.get(row.offer_cat).push(row);
			}
			return ret;
		}),
	);

	constructor(private http: HttpClient) {}
}

import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
	OnChanges,
	SimpleChanges,
} from "@angular/core";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";

@Component({
	selector: "cm-catering-packages-modal",
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<cm-modal [show]="offerCat !== null" (showChange)="offerCatChange.next(null)">
			<ng-template cmModal>
				<div class="row">
					<h4
						*ngIf="title"
						class="col-12 bg-primary text-white font-normal font-weight-normal flex-center px-sm-5 px-3 pt-5 pb-sm-5 pb-3 mb-0"
					>
						{{ title }}
					</h4>

					<fa-icon
						*ngIf="showBack"
						role="button"
						[icon]="faChevronLeft"
						class="back-btn rounded-circle flex-center p-3"
						(click)="back.next()"
					></fa-icon>
				</div>
				<div class="scroll">
					<cm-catering-menu
						*ngIf="looseCats"
						[useCats]="looseCats"
						class="pt-5 px-5 pb-4 catering-menu"
						(saved)="saved = true"
					></cm-catering-menu>

					<cm-catering-packages
						[offer_cat]="offerCat"
						[hideTitles]="true"
						[title1]=""
						[showServiceTypes]="false"
						[inModal]="true"
						(submitted)="saved = true"
					></cm-catering-packages>
				</div>
				<div *ngIf="looseCats || true" class="text-right pb-3">
					<button type="button" class="btn btn-primary" (click)="offerCatChange.next(null)">
						{{ saved ? "Continue" : "Skip" }}
					</button>
				</div>
			</ng-template>
		</cm-modal>
	`,
	styles: [
		`
			:host {
				display: block;
			}

			.scroll {
				max-height: 80vh;
				overflow-y: scroll;
				overflow-x: hidden;
			}

			.back-btn {
				position: absolute;
				top: 1rem;
				left: 1rem;
				width: 1em;
				height: 1em;
				background: #fff;
				cursor: pointer;
				font-size: 20px;
			}

			.back-btn:hover {
				background: #ccc;
			}
		`,
	],
})
export class CateringPackagesModalComponent implements OnChanges {
	@Input() offerCat!: any;
	@Input() looseCats!: string[];
	@Input() title: string | null = null;
	@Input() showBack: boolean = false;

	@Output() back = new EventEmitter<void>();
	@Output() offerCatChange = new EventEmitter<any>();

	faChevronLeft = faChevronLeft;

	saved = false;

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.offerCat || changes.looseCats) {
			this.saved = false;
		}
	}
}

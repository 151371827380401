import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MenuComponent } from "./components/menu/menu.component";
import { ImageModule } from "../image/image.module";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { SharedModule } from "../shared/shared.module";

@NgModule({
	declarations: [MenuComponent],
	imports: [CommonModule, ImageModule, FontAwesomeModule, SharedModule],
	exports: [MenuComponent],
})
export class MenuModule {}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";

@Component({
	selector: "cm-catering-menu-modal",
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<cm-modal [show]="cats !== null" (showChange)="catsChange.next(null)">
			<ng-template cmModal>
				<div class="row">
					<h4
						*ngIf="title"
						class="col-12 bg-primary text-white font-normal font-weight-normal flex-center px-sm-5 px-3 pt-5 pb-sm-5 pb-3 mb-0"
					>
						{{ title }}
					</h4>

					<fa-icon
						*ngIf="showBack"
						role="button"
						[icon]="faChevronLeft"
						class="back-btn rounded-circle flex-center p-3"
						(click)="back.next()"
					></fa-icon>
				</div>
				<cm-catering-menu
					[useCats]="cats"
					class="pt-3 pb-4 catering-menu"
					(saved)="saved = true"
				></cm-catering-menu>
				<div class="text-right pb-3">
					<button type="button" class="btn btn-primary" (click)="catsChange.next(null)">
						{{ saved ? "Continue" : "Skip" }}
					</button>
				</div>
			</ng-template>
		</cm-modal>
	`,
	styles: [
		`
			:host {
				display: block;
			}

			.catering-menu ::ng-deep .menu-items {
				max-height: 80vh;
				overflow-y: scroll;
				overflow-x: hidden;
			}

			.back-btn {
				position: absolute;
				top: 1rem;
				left: 1rem;
				width: 1em;
				height: 1em;
				background: #fff;
				cursor: pointer;
				font-size: 20px;
			}

			.back-btn:hover {
				background: #ccc;
			}
		`,
	],
})
export class CateringMenuModalComponent {
	@Input() cats!: string[] | null;
	@Input() title: string | null = null;
	@Input() showBack: boolean = false;

	@Output() back = new EventEmitter<void>();
	@Output() catsChange = new EventEmitter<string[] | null>();

	faChevronLeft = faChevronLeft;

	saved = false;
}

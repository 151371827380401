import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ModalModule } from "shared";
import { AccountModule } from "../account/account.module";
import { CalendarModule } from "../calendar/calendar.module";
import { FormModule } from "../form/form.module";
import { ImageModule } from "../image/image.module";
import { LayoutModule } from "../layout/layout.module";
import { LocationsSliderComponent } from "../locations-slider/locations-slider.component";
import { Map2Module } from "../map2/map2.module";
import { SliderModule } from "../slider/slider.module";
import { TableModule } from "../table/table.module";
import { UtilModule } from "../util/util.module";
import { CateringAccountEventsComponent } from "./components/account-events.component";
import { CateringAccountInfoComponent } from "./components/account-info.component";
import { CateringAvailabilityComponent } from "./components/catering-availability.component";
import { CateringAvailabilityModalComponent } from "./components/catering-avaliability-modal.component";
import { CateringCatDirective } from "./components/catering-cats/catering-cat.directive";
import { CateringCatsComponent } from "./components/catering-cats/catering-cats.component";
import { CateringDateTimeEditComponent } from "./components/catering-date-time-edit.component";
import { CateringDateTimeComponent } from "./components/catering-date-time.component";
import { CateringEmptyCellComponent } from "./components/catering-empty-cell.component";
import { CateringEventDropdownComponent } from "./components/catering-event-dropdown.component";
import { CateringEventManagerCalendarComponent } from "./components/catering-event-manager-calendar.component";
import { CateringEventManagerComponent } from "./components/catering-event-manager.component";
import { CateringEventPlannerComponent } from "./components/catering-event-planner.component";
import { CateringEventsComponent } from "./components/catering-events.component";
import { CateringGuestCountEditComponent } from "./components/catering-guest-count-edit.component";
import { CateringGuestCountComponent } from "./components/catering-guest-count.component";
import { CateringJobsComponent } from "./components/catering-jobs";
import { CateringLockedWarningComponent } from "./components/catering-locked-warning";
import { CateringMenuItemModalComponent } from "./components/catering-menu-item-modal.component";
import { CateringMenuItemsComponent } from "./components/catering-menu-items.component";
import { CateringMenuModalComponent } from "./components/catering-menu-modal.component";
import { CateringMenuComponent } from "./components/catering-menu.component";
import { CateringPackagesModalComponent } from "./components/catering-packages-modal.component";
import { CateringPackagesComponent } from "./components/catering-packages.component";
import { CateringServiceCellComponent } from "./components/catering-service-cell.component";
import { CateringServiceModalComponent } from "./components/catering-service-modal.component";
import { CateringServiceComponent } from "./components/catering-service.component";
import { CateringSpaceRowComponent } from "./components/catering-space-row.component";
import { CateringSpacesComponent } from "./components/catering-spaces.component";
import { CateringVenueCellComponent } from "./components/catering-venue-cell.component";
import { CateringVenueDetailComponent } from "./components/catering-venue-detail.component";
import { CateringVenueLogoComponent } from "./components/catering-venue-logo.component";
import { CateringVenueSpecsComponent } from "./components/catering-venue-specs.component";
import { CateringVenuesModalComponent } from "./components/catering-venues-modal/catering-venues-modal.component";
import { CustomRequestComponent } from "./components/custom-request.component";
import { GallerySlideshowComponent } from "./components/gallery-slideshow/gallery-slideshow.component";
import { CateringItemTreeComponent } from "./components/item-tree.component";

@NgModule({
	declarations: [
		CateringMenuComponent,
		CateringCatsComponent,
		CateringMenuItemsComponent,
		CateringPackagesComponent,
		CateringCatDirective,
		CateringSpacesComponent,
		CateringSpaceRowComponent,
		CateringAvailabilityComponent,
		CustomRequestComponent,
		CateringEventsComponent,
		CateringEventPlannerComponent,
		CateringDateTimeComponent,
		CateringDateTimeEditComponent,
		CateringEventDropdownComponent,
		CateringGuestCountEditComponent,
		CateringGuestCountComponent,
		CateringVenueCellComponent,
		CateringVenueDetailComponent,
		CateringVenueLogoComponent,
		CateringVenueSpecsComponent,
		CateringVenuesModalComponent,
		CateringEmptyCellComponent,
		CateringServiceCellComponent,
		CateringServiceModalComponent,
		CateringAccountInfoComponent,
		LocationsSliderComponent,
		CateringAccountEventsComponent,
		CateringMenuItemModalComponent,
		CateringEventManagerComponent,
		CateringEventManagerCalendarComponent,
		CateringPackagesModalComponent,
		CateringLockedWarningComponent,
		CateringAvailabilityModalComponent,
		CateringItemTreeComponent,
		CateringServiceComponent,
		CateringMenuModalComponent,
		GallerySlideshowComponent,
		CateringJobsComponent,
	],
	imports: [
		CommonModule,
		AccountModule,
		ModalModule,
		FormsModule,
		FormModule,
		FontAwesomeModule,
		Map2Module,
		CalendarModule,
		RouterModule,
		NgbModule,
		ImageModule,
		SliderModule,
		UtilModule,
		ModalModule,
		LayoutModule,
		TableModule,
	],
	exports: [
		CateringMenuComponent,
		CateringMenuItemsComponent,
		CateringPackagesComponent,
		CateringSpacesComponent,
		CateringAvailabilityComponent,
		CustomRequestComponent,
		CateringEventsComponent,
		CateringEventPlannerComponent,
		CateringEventDropdownComponent,
		CateringAccountInfoComponent,
		LocationsSliderComponent,
		CateringAccountEventsComponent,
		CateringEventManagerComponent,
		CateringEventManagerCalendarComponent,
		CateringLockedWarningComponent,
		GallerySlideshowComponent,
		CateringJobsComponent,
	],
})
export class CateringModule {}

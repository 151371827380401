import {
	ChangeDetectionStrategy,
	Component,
	ContentChildren,
	EventEmitter,
	Input,
	Output,
	QueryList,
} from "@angular/core";
import { CateringCatDirective } from "./catering-cat.directive";

@Component({
	selector: "cm-catering-cats",
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<div class="row h-100">
			<div *ngFor="let cat of cats; index as i" class="{{ cols() }} pb-3 pb-sm-5">
				<div
					role="button"
					class="shadow cat position-relative h-100"
					(click)="catIndexChange.next(i)"
					[ngClass]="{ active: catIndex === i }"
				>
					<div
						class="h-100 cat-bg"
						[ngClass]="{ grayscale: grayscale }"
						[ngStyle]="{
							'background-image':
								'url(' + (cat.image || 'https://dms.rvimg.com/s3static/18/generic-menu.jpg') + ')'
						}"
					>
						<div
							class="border-heavy h-100 flex-center text-center"
							style="line-height: 1;"
							[ngClass]="{
								arrow: arrow,
								'border-transparent': !border,
								'border-primary': catIndex === i
							}"
						>
							<ng-container *ngIf="catContents.toArray()[i] as catText; else h3">
								<ng-container
									*ngTemplateOutlet="catText.templateRef; context: { img: cat.image }"
								></ng-container>
							</ng-container>
							<ng-template #h3>
								<div class="h3 text-white text-center mb-0 item-cat text-shadow">
									{{ cat.name | titlecase }}
								</div>
							</ng-template>
						</div>
					</div>
				</div>
			</div>
		</div>
	`,
	styleUrls: ["./catering-cats.component.scss"],
})
export class CateringCatsComponent {
	@Input() arrow: boolean = true;
	@Input() border: boolean = true;
	@Input() catIndex: number = 0;
	@Input() cats!: { name: string; image: string }[];
	@Input() grayscale: boolean = true;

	@Output() catIndexChange = new EventEmitter<number>();

	@ContentChildren(CateringCatDirective) catContents!: QueryList<CateringCatDirective>;

	cols() {
		const xl = 12 / this.cats.length;
		return `col-xl-${Math.max(xl, 3)} col-lg-${Math.max(xl, 4)} col-md-${Math.max(xl, 6)} col-sm-12`;
	}
}

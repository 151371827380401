import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CampaignPickerComponent } from "./components/campaign-picker/campaign-picker.component";

@NgModule({
	declarations: [CampaignPickerComponent],
	exports: [CampaignPickerComponent],
	imports: [CommonModule, FormsModule, NgbModule],
})
export class AdwordsModule {}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, SimpleChanges } from "@angular/core";
import { ImagePipe } from "@core/app/image/pipes/image.pipe";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { iter } from "shared/common";
import { CateringMenuService } from "../catering-menu.service";

@Component({
	selector: "cm-catering-menu",
	template: `
		<ng-container *ngIf="cats$ | async as cats">
			<cm-catering-cats *ngIf="cats.length > 1" [arrow]="false" [cats]="cats" [(catIndex)]="catIndex">
				<ng-template cmCateringCat *ngFor="let cat of cats">
					<span class="cat text-white text-shadow">{{ cat.name }}</span>
				</ng-template>
			</cm-catering-cats>
			<cm-catering-menu-items
				[cats]="cats[catIndex].cats"
				class="menu-items"
				(saved)="saved.next()"
			></cm-catering-menu-items>
		</ng-container>
	`,
	styles: [
		`
			:host {
				display: block;
			}
			.cat {
				font-size: 2rem;
			}
			@media (max-width: 767px) {
				h2 {
					font-size: 2.2em;
				}
			}
		`,
	],
	providers: [ImagePipe],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CateringMenuComponent {
	@Input() useCats: string[] = [];

	@Output() saved = new EventEmitter();

	catIndex = 0;
	useCatsBS = new BehaviorSubject<string[]>([]);

	cats$: Observable<{ name: string; image: string; cats: string[] }[]> = combineLatest(
		this.useCatsBS,
		this.menu.itemsByCat$,
		this.menu.allCatsMap$,
	).pipe(
		tap(() => (this.catIndex = 0)),
		map(([useCats, res, allCatsMap]) =>
			iter(res)
				.filter(([cat]) => useCats.includes(cat))
				.map(([cat]) => {
					const catObj = allCatsMap.get(cat);
					return {
						name: cat,
						image: this.imagePipe.transform(catObj.img_dir + catObj.img_file),
						cats: [cat],
					};
				})
				.toArray(),
		),
	);

	constructor(private menu: CateringMenuService, private imagePipe: ImagePipe) {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.useCats) {
			this.useCatsBS.next(this.useCats);
		}
	}
}

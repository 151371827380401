import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { BootstrapModule } from "../bootstrap/bootstrap.module";
import { ImageModule } from "../image/image.module";
import { MfrBrandsComponent } from "./components/brands/brands.component";
import { LayoutModule } from "../layout/layout.module";
import { MfrSummaryComponent } from "./components/summary/summary.component";
import { MfrTabsComponent } from "./components/tabs/tabs.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

@NgModule({
	declarations: [MfrBrandsComponent, MfrSummaryComponent, MfrTabsComponent],
	imports: [BootstrapModule, CommonModule, FontAwesomeModule, ImageModule, NgbModule, LayoutModule, RouterModule],
	exports: [MfrBrandsComponent, MfrSummaryComponent, MfrTabsComponent],
})
export class MfrModule {}

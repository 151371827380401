// dependencies
import { HttpClientModule } from "@angular/common/http";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { SharedModule } from "@core/app/shared/shared.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { Ng2UiAuthModule, StorageType } from "ng2-ui-auth";
import { EqualValidatorDirective } from "./directive/equal-validator.directive";
import { FooterModule } from "./footer/footer.module";
import { HeaderModule } from "./header/header.module";
import { ImageModule } from "./image/image.module";
import { InventoryModule } from "./inventory/inventory.module";
import { PromotionPopupComponent } from "./promotion/components/promotion-popup/promotion-popup.component";
import { RouteResolver } from "./services/route-resolver.service";
import { SliderModule } from "./slider/slider.module";
import { UserWidgetComponent } from "./user-widget/user-widget.component";
import { HallsModule } from "./halls/halls.module";
import { config } from "@fortawesome/fontawesome-svg-core";

const auth = {
	storageType: StorageType.LOCAL_STORAGE,
};
if (typeof window === "undefined") {
	auth.storageType = StorageType.MEMORY;
}

@NgModule({
	declarations: [EqualValidatorDirective, PromotionPopupComponent, UserWidgetComponent],
	imports: [
		BrowserAnimationsModule,
		BrowserModule,
		HttpClientModule,
		NgbModule,
		Ng2UiAuthModule.forRoot(auth),
		RouterModule.forChild([]),
		SharedModule,
		SliderModule,
		FooterModule,
		InventoryModule,
		ImageModule,
		HeaderModule,
		HallsModule,
	],
	exports: [
		EqualValidatorDirective,
		NgbModule,
		PromotionPopupComponent,
		SharedModule,
		UserWidgetComponent,
		SliderModule,
		HeaderModule,
		FooterModule,
		InventoryModule,
		ImageModule,
		HallsModule,
	],
})
export class CoreModule {
	static forRoot(): ModuleWithProviders<CoreModule> {
		return { ngModule: CoreModule, providers: [RouteResolver] };
	}
	constructor() {
		config.autoAddCss = false;
	}
}

import { DOCUMENT } from "@angular/common";
import { ChangeDetectionStrategy, Component, Inject, Renderer2 } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { MenuHelperService } from "@core/app/menu/services/menu-helper.service";
import { UserService } from "@core/app/services/user.service";
import { faBars, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { IPageData } from "@model/page-data";
import { BehaviorSubject, merge, ReplaySubject } from "rxjs";
import { distinctUntilChanged, filter, map, startWith, takeUntil } from "rxjs/operators";
import { ViewportService } from "shared/common";

@Component({
	selector: "cm-header",
	template: `
		<header class="header" itemscope itemtype="http://schema.org/WPHeader">
			<div class="d-flex shadow">
				<div class="d-flex align-items-center justify-content-end tablecloth left">
					<ng-container *ngIf="!(viewportService.bp.up.xl$ | async)"> </ng-container>
					<ng-container *ngIf="viewportService.bp.up.xl$ | async">
						<div class="menu-item mx-xl-2 mx-1 my-4" style="z-index:1;">
							<a routerLink="/menu">Menu</a>
						</div>
						<div class="menu-item mx-xl-2 mx-1 my-4" style="z-index:1;">
							<a routerLink="/lunch-menu">Lunch</a>
						</div>
						<div class="menu-item mx-xl-2 mx-1 my-4" style="z-index:1;">
							<a routerLink="/our-spaces">Spaces</a>
						</div>
					</ng-container>
				</div>
				<div class="position-relative">
					<div
						class="plate d-flex justify-content-center"
						itemscope
						itemtype="http://schema.org/Organization"
					>
						<meta itemprop="logo" [content]="logoUrl | image : 'm'" />
						<meta itemprop="url" [content]="siteUrl" />
						<meta itemprop="name" [content]="dealer" />
						<a routerLink="/" class="pt-2 pt-md-3 pb-5 text-center">
							<img [src]="logoUrl | image : 'm'" [alt]="logoAlt || ''" class="mh-100 img-fluid" />
						</a>
					</div>
				</div>
				<div class="d-flex align-items-center tablecloth right">
					<ng-container *ngIf="viewportService.bp.up.xl$ | async">
						<div class="menu-item mx-xl-2 mx-1 my-4" style="z-index:1;">
							<a routerLink="/gallery">Gallery</a>
						</div>
						<div class="menu-item mx-xl-2 mx-1 my-4" style="z-index:1;">
							<a routerLink="/contact">Contact</a>
						</div>
						<div
							class="dropdown"
							style="z-index:1;"
							(mouseover)="dropdownActive = 0"
							(mouseleave)="dropdownActive = null"
						>
							<div class="menu-item px-xl-2 px-1 py-4 dropdown-toggle">Restaurants</div>
							<div
								class="dropdown-menu mb-0 mt-n2 py-0 rounded-0"
								[ngStyle]="{ display: dropdownActive === 0 ? 'block' : 'none' }"
							>
								<a href="https://pintsandquarts.com/" target="_blank" class="dropdown-item px-4 py-2"
									>Pints & Quarts</a
								>
								<a href="https://thedeckmkg.com/" target="_blank" class="dropdown-item px-4 py-2"
									>The Deck</a
								>
								<a
									href="https://harris-hospitality.square.site/product/harris-hospitality-gift-card/1?cs=true&cst=custom"
									target="_blank"
									class="dropdown-item px-4 py-2"
									>Purchase Gift Cards</a
								>
								<a
									href="https://www.toasttab.com/the-deck-n-a/rewardsSignup"
									target="_blank"
									class="dropdown-item px-4 py-2"
									>Restaurant Loyalty Program</a
								>
								<a
									href="https://www.toasttab.com/pints-quarts-950-w-norton-ave/findcard"
									target="_blank"
									class="dropdown-item px-4 py-2"
									>Gift Card Balance</a
								>
								<a
									href="https://pintsandquarts.com/join-the-team/"
									target="_blank"
									class="dropdown-item px-4 py-2"
									>Employment - Pints & Quarts</a
								>
								<a
									href="https://thedeckmkg.com/employment/"
									target="_blank"
									class="dropdown-item px-4 py-2"
									>Employment - The Deck</a
								>
							</div>
						</div>
						<div class="flex-grow-1 d-flex justify-content-end pr-1 font-weight-normal">
							<div class="text-right">
								<div class="greeting text-nowrap">Hello, {{ friendlyName$ | async }}!</div>
								<small class="d-block text-uppercase text-muted text-nowrap">
									<ng-container *ngIf="user.loggedIn$ | async; else login">
										<a routerLink="/my-account" class="text-muted">My Account</a>
										|
										<a href="javascript:void(0)" class="text-muted" (click)="user.logOut()">
											Sign Out
										</a>
									</ng-container>
									<ng-template #login>
										<a href="javascript:void(0)" class="text-muted" (click)="showLogin = true">
											Sign In
										</a>
									</ng-template>
								</small>
							</div>
						</div>
					</ng-container>
					<div
						*ngIf="!(viewportService.bp.up.xl$ | async)"
						class="flex-grow-1 d-flex justify-content-end pr-1 font-weight-normal"
					>
						<div
							class="hamburger-container menu-item mx-xl-2 mx-2 my-4 pointer-cursor position-relative"
							(click)="toggleMenuS.next(!toggleMenuS.value)"
						>
							<fa-icon [icon]="faBars"></fa-icon>
						</div>
					</div>
				</div>
			</div>
		</header>
		<div class="d-none d-print-flex justify-content-center">
			<img [src]="logoUrl | image : 's'" [alt]="logoAlt || ''" class="mh-100 img-fluid" />
		</div>

		<cm-login-modal [(show)]="showLogin" (loggedIn)="showLogin = false"></cm-login-modal>
		<div
			*ngIf="(menuShow$ | async) && !(viewportService.bp.up.xl$ | async)"
			class="cm-menu-mobile-overlay"
			(click)="toggleMenuS.next(false)"
		></div>
		<div
			class="cm-menu-mobile"
			*ngIf="!(viewportService.bp.up.xl$ | async)"
			[ngClass]="{ visible: menuShow$ | async }"
		>
			<div class="cm-menu-mobile-header bg-primary text-white">
				<span class="greeting text-nowrap">Hello, {{ friendlyName$ | async }}!</span>
				<div class="user-buttons" *ngIf="user.loggedIn$ | async">
					<hr />
					<a routerLink="/my-account" class="text-white">My Account</a> |
					<a href="javascript:void(0)" class="text-white" (click)="user.logOut()"> Sign Out </a>
				</div>
				<div class="user-buttons" *ngIf="!(user.loggedIn$ | async)">
					<hr />
					<a href="javascript:void(0)" class="text-white" (click)="showLogin = true"> Sign In </a>
				</div>
				<hr />
			</div>
			<ul class="cm-menu-mobile-ul">
				<li><a routerLink="/menu">Menu</a></li>
				<li><a routerLink="/lunch-menu">Lunch</a></li>
				<li><a routerLink="/our-spaces">Spaces</a></li>
				<li><a routerLink="/gallery">Gallery</a></li>
				<li><a routerLink="/contact">Contact</a></li>
				<li>
					<a>Restaurants</a>
					<ul class="cm-menu-mobile-ul">
						<li>
							<a href="https://pintsandquarts.com/" target="_blank">Pints & Quarts</a>
						</li>
						<li>
							<a href="https://thedeckmkg.com/" target="_blank">The Deck</a>
						</li>
						<li>
							<a
								href="https://harris-hospitality.square.site/product/harris-hospitality-gift-card/1?cs=true&cst=custom"
								target="_blank"
								>Purchase Gift Cards</a
							>
						</li>
						<li>
							<a href="https://www.toasttab.com/the-deck-n-a/rewardsSignup" target="_blank"
								>Restaurant Loyalty Program</a
							>
						</li>
						<li>
							<a href="https://www.toasttab.com/pints-quarts-950-w-norton-ave/findcard" target="_blank"
								>Gift Card Balance</a
							>
						</li>
						<li>
							<a href="https://pintsandquarts.com/join-the-team/" target="_blank"
								>Employment - Pints & Quarts</a
							>
						</li>
						<li>
							<a href="https://thedeckmkg.com/employment/" target="_blank">Employment - The Deck</a>
						</li>
					</ul>
				</li>
			</ul>
		</div>
	`,
	styleUrls: [`./header.component.scss`],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
	siteUrl: string;
	dealer: string;
	logoAlt: string;
	logoUrl: string;
	menus: any;
	friendlyName$ = this.user.user$.pipe(map((user) => (user ? user.first_name || user.user_login : "Guest")));
	showLogin = false;
	showEvent = false;
	faBars = faBars;
	faTimes = faTimes;

	toggleMenuS = new BehaviorSubject(false);
	manager$ = this.user.permissions$.pipe(map((perms) => perms.hasPermission(["catering", "manager"])));

	menuShow$ = merge(
		this.toggleMenuS,
		this.router.events.pipe(
			filter((event) => event instanceof NavigationEnd),
			map(() => false),
		),
	).pipe(startWith(false), distinctUntilChanged());

	ngOnDestroyRS: ReplaySubject<void> = new ReplaySubject();

	dropdownActive = null;

	constructor(
		public user: UserService,
		public viewportService: ViewportService,
		private router: Router,
		menuHelperService: MenuHelperService,
		renderer: Renderer2,
		@Inject(DOCUMENT) private document: Document,
		@Inject("PAGE_DATA") pageData: IPageData,
	) {
		this.siteUrl = "https://" + pageData.host + "/";
		const dealerInfo = pageData.headerData.queries.GetDealerInfo[0];
		this.dealer = dealerInfo.dealer;
		this.logoAlt = dealerInfo.img_alt;

		this.logoUrl = dealerInfo.img_dir + dealerInfo.img_file;

		this.menus = menuHelperService.getMenu();
		this.menuShow$.pipe(takeUntil(this.ngOnDestroyRS)).subscribe((menuShow) => {
			if (menuShow) {
				renderer.addClass(this.document.body, "overflow-hidden");
			} else {
				renderer.removeClass(this.document.body, "overflow-hidden");
			}
		});
	}

	ngOnDestroy(): void {
		this.ngOnDestroyRS.next();
		this.ngOnDestroyRS.complete();
	}
}

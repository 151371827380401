import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
	selector: "cm-login-modal",
	template: `
		<cm-modal [show]="show" (showChange)="showChange.next($event)">
			<ng-template cmModal>
				<div class="row">
					<h4
						class="col-12 bg-primary text-white font-normal font-weight-normal flex-center px-sm-5 px-3 pt-5 pb-sm-5 pb-3 mb-0"
					>
						Please log in or sign up to build an event:
					</h4>
					<div class="col-lg-6 col-12 p-sm-5 p-3 d-flex align-items-center">
						<div class="w-100">
							<cm-account (loggedIn)="loggedIn.next()"></cm-account>
						</div>
					</div>

					<div class="col-lg-6 col-12 p-sm-5 p-3" style="background: #dfdfdf">
						<img src="https://dms.rvimg.com/s3static/18/login-right.jpg" class="rounded shadow img-fluid" />
						<div class="h5 font-normal font-weight-normal my-4">
							Make planning your event fast & easy with our free event builder!
						</div>
						<ul class="list-unstyled">
							<li class="mt-2 d-flex align-items-center" style="line-height: 1.3;">
								<img src="https://dms.rvimg.com/s3static/18/check.png" class="mr-3" /> Comprehensive,
								easy-to-use planning tools!
							</li>
							<li class="mt-2 d-flex align-items-center">
								<img src="https://dms.rvimg.com/s3static/18/check.png" class="mr-3" /> Receive a quote
								in minutes!
							</li>
							<li class="mt-2 d-flex align-items-center">
								<img src="https://dms.rvimg.com/s3static/18/check.png" class="mr-3" /> Order & book your
								event right online!
							</li>
							<li class="mt-2 d-flex align-items-center" style="line-height: 1.3;">
								<img src="https://dms.rvimg.com/s3static/18/check.png" class="mr-3" /> Recurring event?
								It’s easy to order again!
							</li>
							<li class="mt-2 d-flex align-items-center" style="line-height: 1.3;">
								<img src="https://dms.rvimg.com/s3static/18/check.png" class="mr-3" /> Additional
								savings from our email list!
							</li>
							<li class="mt-2 d-flex align-items-center" style="line-height: 1.3;">
								<img src="https://dms.rvimg.com/s3static/18/check.png" class="mr-3" /> Help your guests
								enjoy their stay with our local visitor's guide!
							</li>
							<li class="mt-2 d-flex align-items-center">
								<img src="https://dms.rvimg.com/s3static/18/check.png" class="mr-3" />
								<b style="font-size:1.5rem">
									100% free!
								</b>
							</li>
						</ul>
					</div>
				</div>
			</ng-template>
		</cm-modal>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginModalComponent {
	@Input() show!: boolean;

	@Output() loggedIn = new EventEmitter<void>();
	@Output() showChange = new EventEmitter<boolean>();
}

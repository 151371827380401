import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { LogoutButtonComponent } from "./components/logout-button/logout-button.component";
import { SubmitButtonComponent } from "./components/submit-button/submit-button.component";
import { ButtonComponent } from "./components/button/button.component";
import { RouterModule } from "@angular/router";

@NgModule({
	declarations: [LogoutButtonComponent, SubmitButtonComponent, ButtonComponent],
	imports: [CommonModule, FontAwesomeModule, RouterModule],
	exports: [LogoutButtonComponent, SubmitButtonComponent, ButtonComponent],
})
export class ButtonModule {}

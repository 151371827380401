import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { ImageModule } from "@core/app/image/image.module";
import { LayoutModule } from "@core/app/layout/layout.module";
import { SearchModule } from "@core/app/search/search.module";
import { CheckboxSelectorComponent } from "@core/app/shared/components/search-filter/components/checkbox-selector/checkbox-selector.component";
import { KeywordSelectorComponent } from "@core/app/shared/components/search-filter/components/keyword-selector/keyword-selector.component";
import { PredictiveSelectorComponent } from "@core/app/shared/components/search-filter/components/predictive-selector/predictive-selector.component";
import { RangeInputSelectorComponent } from "@core/app/shared/components/search-filter/components/range-input-selector/range-input-selector.component";
import { RangeSelectorComponent } from "@core/app/shared/components/search-filter/components/range-selector/range-selector.component";
import { SelectSelectorComponent } from "@core/app/shared/components/search-filter/components/select-selector/select-selector.component";
import { SearchFilterComponent } from "@core/app/shared/components/search-filter/search-filter.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { UtilModule } from "../util/util.module";

@NgModule({
	declarations: [
		SearchFilterComponent,
		RangeSelectorComponent,
		RangeInputSelectorComponent,
		CheckboxSelectorComponent,
		KeywordSelectorComponent,
		PredictiveSelectorComponent,
		SelectSelectorComponent,
	],
	imports: [
		CommonModule,
		NgbModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		ImageModule,
		FontAwesomeModule,
		SearchModule,
		LayoutModule,
		UtilModule,
	],
	exports: [
		SearchFilterComponent,
		RangeSelectorComponent,
		RangeInputSelectorComponent,
		CheckboxSelectorComponent,
		KeywordSelectorComponent,
		PredictiveSelectorComponent,
		SelectSelectorComponent,
	],
})
export class InventoryModule {}

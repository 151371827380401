import { iterFiles } from "@common/iter";
import { IOption, none } from "@common/option";
import { ILineItem } from "@model/line-item";

export class FieldData {
	defId: number;
	name: string;
	label: string;
	typeId: IOption<string> = none();
	choices: IOption<any[]> = none();
	intro: IOption<string> = none();
	maximum: IOption<number> = none();
	required: IOption<boolean> = none();
	stmt: IOption<string> = none();
	default: IOption<string | number> = none();

	constructor(defId: number, name: string, label: string) {
		this.defId = defId;
		this.name = name;
		this.label = label;
	}
}

export class Field {
	// TODO: generate from database
	static fromData(data: FieldData): Field {
		if (data.typeId.isSome()) {
			const typeId = data.typeId.unwrap();
			if (typeId == "4") {
				const ret = new FieldRadio(
					data.name,
					data.label,
					data.intro.asNullable(),
					data.choices.unwrap().map((row: any) => FieldOption.fromDb(data.stmt.asNullable(), data.name, row)),
				);
				ret.value = data.default.asNullable();
				return ret;
			} else if (typeId == "5") {
				const ret = new FieldCheckbox(
					data.name,
					data.label,
					data.intro.asNullable(),
					data.choices
						.map((choices) =>
							choices.map((row: any) => FieldOption.fromDb(data.stmt.asNullable(), data.name, row)),
						)
						.asNullable(),
				);
				ret.value = data.default.asNullable();
				return ret;
			} else if (typeId == "18" || typeId == "37" || typeId == "27") {
				const ret = new FieldSelect(
					data.name,
					data.label,
					data.intro.asNullable(),
					data.choices.unwrap().map((row: any) => FieldOption.fromDb(data.stmt.asNullable(), data.name, row)),
					data.required.unwrap(),
				);
				if (typeId == "37") {
					ret.multiple = true;
				}
				ret.value = data.default.asNullable();
				const options = data.choices
					.unwrap()
					.map((row: any) => FieldOption.fromDb(data.stmt.asNullable(), data.name, row));
				if (options.length === 1) {
					ret.value = options[0].value;
				}
				return ret;
			}
		}

		switch (data.defId) {
			case FieldDef.ShortText: {
				const ret = new FieldText(
					data.name,
					"text",
					data.label,
					data.intro.asNullable(),
					data.required.unwrap(),
					data.maximum.asNullable(),
					false,
				);
				ret.value = data.default.asNullable();
				return ret;
			}
			case FieldDef.Email: {
				const ret = new FieldText(
					data.name,
					"email",
					data.label,
					data.intro.asNullable(),
					data.required.unwrap(),
					data.maximum.asNullable(),
					false,
				);
				ret.value = data.default.asNullable();
				ret.pattern = "^[a-zA-Z0-9._!#$%&'*+\\/=?^`{|}~-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z0-9.-]+$";
				return ret;
			}
			case FieldDef.Month: {
				const ret = new FieldMonthYear(data.name, data.label, data.intro.asNullable());
				ret.value = data.default.asNullable();
				return ret;
			}
			case FieldDef.ListItem: {
				const ret = new FieldListItem(data.name, data.label, data.intro.asNullable());
				ret.value = [{ desc: null, number: 0 }];
				return ret;
			}
			case FieldDef.Number: {
				const ret = new FieldNumber(
					data.name,
					data.label,
					data.intro.asNullable(),
					data.required.unwrap(),
					data.maximum.asNullable(),
				);
				ret.value = data.default.asNullable();
				ret.pattern = "^[0-9\\.]+$";
				return ret;
			}
			case FieldDef.Telephone: {
				const ret = new FieldText(
					data.name,
					"tel",
					data.label,
					data.intro.asNullable(),
					data.required.unwrap(),
					data.maximum.asNullable(),
					false,
				);
				ret.value = data.default.asNullable();
				ret.pattern =
					"^([0-9]( |-)?)?(([0-9]{3})?|\\([0-9]{3}\\))( |-)?([0-9]{3}( |-)?[0-9]{4}|[a-zA-Z0-9]{7})$";
				return ret;
			}
			case FieldDef.LongText: {
				const ret = new FieldTextarea(
					data.name,
					data.label,
					data.intro.asNullable(),
					data.required.unwrap(),
					data.maximum.asNullable(),
					false,
				);
				ret.value = data.default.asNullable();
				return ret;
			}
			case FieldDef.FileUpload: {
				return new FieldFile(data.name, data.label, data.intro.asNullable(), data.required.unwrap());
			}
			case FieldDef.Hidden: {
				const ret = new FieldText(
					data.name,
					"hidden",
					data.label,
					data.intro.asNullable(),
					data.required.unwrap(),
					data.maximum.asNullable(),
					false,
				);
				ret.value = data.default.asNullable();
				return ret;
			}
			case FieldDef.PostalCode: {
				const ret = new FieldText(
					data.name,
					"text",
					data.label,
					data.intro.asNullable(),
					data.required.unwrap(),
					data.maximum.asNullable(),
					false,
				);
				ret.value = data.default.asNullable();
				ret.autocomplete = "postal-code";
				return ret;
			}
			case FieldDef.SocialSecurityNumber: {
				const ret = new FieldText(
					data.name,
					"text",
					data.label,
					data.intro.asNullable(),
					data.required.unwrap(),
					data.maximum.asNullable(),
					false,
				);
				ret.value = data.default.asNullable();
				ret.pattern = "^\\d{3}-?\\d{2}-?\\d{4}$";
				return ret;
			}
			case FieldDef.FileField: {
				return new FieldFile(data.name, data.label, data.intro.asNullable(), data.required.unwrap());
			}
			case FieldDef.ShortTextDisplayOnly: {
				const ret = new FieldText(
					data.name,
					"text",
					data.label,
					data.intro.asNullable(),
					data.required.unwrap(),
					data.maximum.asNullable(),
					false,
				);
				ret.value = data.default.asNullable();
				ret.disabled = true;
				return ret;
			}
			case FieldDef.Compare: {
				return new FieldCompare(data.name, data.label, data.intro.asNullable());
			}
			case FieldDef.CannedResponse: {
				const ret = new FieldCannedResponse(
					data.name,
					data.label,
					data.intro.asNullable(),
					data.required.unwrap(),
					data.maximum.asNullable(),
					false,
				);
				ret.value = data.default.asNullable();
				return ret;
			}
			case FieldDef.Vin: {
				const ret = new FieldText(
					data.name,
					"text",
					data.label,
					data.intro.asNullable(),
					data.required.unwrap(),
					data.maximum.asNullable(),
					false,
				);
				ret.value = data.default.asNullable();
				return ret;
			}
			case FieldDef.MultipleEmails: {
				const ret = new FieldTextMultiple(
					data.name,
					"email",
					data.label,
					data.intro.asNullable(),
					data.required.unwrap(),
					data.maximum.asNullable(),
					false,
				);
				ret.value = data.default.asNullable();
				ret.pattern = "^[a-zA-Z0-9._!#$%&'*+\\/=?^`{|}~-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z0-9.-]+$";
				return ret;
			}
			case FieldDef.MultipleFile: {
				return new FieldFileMultiple(data.name, data.label, data.intro.asNullable(), data.required.unwrap());
			}
			case FieldDef.Char4: {
				const ret = new FieldText(
					data.name,
					"text",
					data.label,
					data.intro.asNullable(),
					data.required.unwrap(),
					data.maximum.asNullable(),
					false,
				);
				ret.value = data.default.asNullable();
				return ret;
			}
			case FieldDef.ShortTextNoCounter: {
				const ret = new FieldText(
					data.name,
					"text",
					data.label,
					data.intro.asNullable(),
					data.required.unwrap(),
					data.maximum.asNullable(),
					true,
				);
				ret.value = data.default.asNullable();
				return ret;
			}
			case FieldDef.EmailNoCounter: {
				const ret = new FieldText(
					data.name,
					"email",
					data.label,
					data.intro.asNullable(),
					data.required.unwrap(),
					data.maximum.asNullable(),
					true,
				);
				ret.value = data.default.asNullable();
				ret.pattern = "^[a-zA-Z0-9._!#$%&'*+\\/=?^`{|}~-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z0-9.-]+$";
				return ret;
			}
			case FieldDef.TelephoneNoCounter: {
				const ret = new FieldText(
					data.name,
					"tel",
					data.label,
					data.intro.asNullable(),
					data.required.unwrap(),
					data.maximum.asNullable(),
					true,
				);
				ret.value = data.default.asNullable();
				ret.pattern =
					"^([0-9]( |-)?)?(([0-9]{3})?|\\([0-9]{3}\\))( |-)?([0-9]{3}( |-)?[0-9]{4}|[a-zA-Z0-9]{7})$";
				return ret;
			}
			case FieldDef.LongTextNoCounter: {
				const ret = new FieldTextarea(
					data.name,
					data.label,
					data.intro.asNullable(),
					data.required.unwrap(),
					data.maximum.asNullable(),
					true,
				);
				ret.value = data.default.asNullable();
				return ret;
			}
			case FieldDef.MonthlyPayment: {
				const ret = new FieldText(
					data.name,
					"text",
					data.label,
					data.intro.asNullable(),
					data.required.unwrap(),
					data.maximum.asNullable(),
					true,
				);
				ret.value = data.default.asNullable();
				ret.pattern = "^[0-9\\.]+$";
				return ret;
			}
			case FieldDef.PostalCodeNoCounter: {
				const ret = new FieldText(
					data.name,
					"text",
					data.label,
					data.intro.asNullable(),
					data.required.unwrap(),
					data.maximum.asNullable(),
					true,
				);
				ret.value = data.default.asNullable();
				ret.autocomplete = "postal-code";
				return ret;
			}
			case FieldDef.BirthDate: {
				const ret = new FieldDate(data.name, data.label, data.intro.asNullable(), data.required.unwrap());
				ret.value = data.default.asNullable();
				ret.pattern = "^\\d{2}/\\d{2}/\\d{4}$";
				return ret;
			}
			case FieldDef.Date: {
				const ret = new FieldDate(data.name, data.label, data.intro.asNullable(), data.required.unwrap());
				ret.value = data.default.asNullable();
				return ret;
			}
			case FieldDef.DateTime: {
				const ret = new FieldDateTime(data.name, data.label, data.intro.asNullable(), data.required.unwrap());
				ret.value = data.default.asNullable();
				return ret;
			}
			case FieldDef.ServiceIssue: {
				return new FieldServiceIssue(data.name, data.label, data.intro.asNullable());
			}
			case FieldDef.UserAuthGroups: {
				return new FieldUserAuthGroups(data.name, data.label, data.intro.asNullable());
			}
			default: {
				console.error(`Unrecognized fld_defid ${data.defId}`);
				return new FieldUnknown(data.name);
				// throw new Error(`Unrecognized fld_defid ${data.defId}`);
			}
		}
	}

	name: string;
	value?: any;

	constructor(name: string) {
		this.name = name;
	}

	clone(): Field {
		throw new Error(`Child class must override \`${this.clone.name}\``);
	}

	getValue(): any {
		throw new Error(`Child class must override \`${this.getValue.name}\``);
	}

	setValue(val: any) {
		const invalid = (expected: string) => {
			const type = val === null ? "null" : val === undefined ? "undefined" : val.constructor.name;
			throw new Error(
				`\`typeof val\` is incompatible with field's value type (field ${this.name}, expected ${expected}, got ${type})`,
			);
		};

		if (this instanceof FieldFile) {
			if (!(val.constructor.name === "FileList")) {
				invalid("FileList");
			}

			this.value = val;
		} else if (this instanceof FieldFileMultiple) {
			if (!Array.isArray(val) || val.some((x) => x !== null && !(x.constructor.name === "FileList"))) {
				invalid("FileList[]");
			}

			this.value = val;
		} else {
			this.value = val;
		}
	}
}

enum FieldDef {
	ShortText = 2,
	Email = 19,
	Month = 20,
	Number = 21,
	Telephone = 23,
	LongText = 30,
	FileUpload = 37,
	Hidden = 39,
	SelectMultiple = 52,
	PostalCode = 53,
	SocialSecurityNumber = 58,
	FileField = 67,
	ShortTextDisplayOnly = 70,
	Compare = 78,
	CannedResponse = 80,
	Vin = 82,
	MultipleEmails = 83,
	MultipleFile = 84,
	Char4 = 85,
	ShortTextNoCounter = 87,
	EmailNoCounter = 89,
	TelephoneNoCounter = 90,
	LongTextNoCounter = 91,
	MonthlyPayment = 92,
	BirthDate = 94,
	Date = 16,
	PostalCodeNoCounter = 99,
	ServiceIssue = 66,
	ListItem = 101,
	UserAuthGroups = 102,
	DateTime = 18,
}

export class FieldListItem extends Field {
	label: string;
	intro: string | null;
	disabled: boolean = false;
	value: ILineItem[] = [{ desc: null, number: 0 }];

	constructor(name: string, label: string, intro: string | null) {
		super(name);

		this.label = label;
		this.intro = intro;
	}

	clone(): FieldListItem {
		const ret = new FieldListItem(this.name, this.label, this.intro);
		ret.disabled = this.disabled;
		ret.value = this.value;
		return ret;
	}

	getValue(): ILineItem[] {
		return this.value;
	}
}

export class FieldMonthYear extends Field {
	label: string;
	intro: string | null;
	disabled: boolean = false;
	value: string | number | null = "";

	constructor(name: string, label: string, intro: string | null) {
		super(name);

		this.label = label;
		this.intro = intro;
	}

	clone(): FieldMonthYear {
		const ret = new FieldMonthYear(this.name, this.label, this.intro);
		ret.disabled = this.disabled;
		ret.value = this.value;
		return ret;
	}

	getValue(): string | number | null {
		return this.value;
	}
}

export class FieldFile extends Field {
	static getBase64(blob: Blob): Promise<string> {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = () => resolve(reader.result as string);
			reader.onerror = (error) => reject(error);
			reader.readAsDataURL(blob);
		});
	}

	label: string;
	intro: string | null;
	required: boolean;
	disabled: boolean = false;
	value: FileList | null = null;

	constructor(name: string, label: string, intro: string | null, required: boolean) {
		super(name);

		this.label = label;
		this.intro = intro;
		this.required = required;
	}

	clone(): FieldFile {
		const ret = new FieldFile(this.name, this.label, this.intro, this.required);
		ret.disabled = this.disabled;
		ret.value = this.value;
		return ret;
	}

	getValue(): FileList | null {
		return this.value;
	}

	getValuesAsBase64(): Promise<FileBase64[] | null> {
		if (this.value === null) {
			return Promise.resolve(null);
		}

		return Promise.all(
			iterFiles(this.value)
				.map(async (file) => new FileBase64(file.name, await FieldFile.getBase64(file)))
				.toArray(),
		);
	}
}

export class FieldFileMultiple extends Field {
	private static getBase64(blob: Blob): Promise<string> {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = () => resolve(reader.result as string);
			reader.onerror = (error) => reject(error);
			reader.readAsDataURL(blob);
		});
	}

	label: string;
	intro: string | null;
	required: boolean;
	disabled: boolean = false;
	value: (FileList | null)[] = [null];

	constructor(name: string, label: string, intro: string | null, required: boolean) {
		super(name);

		this.label = label;
		this.intro = intro;
		this.required = required;
	}

	clone(): FieldFileMultiple {
		const ret = new FieldFileMultiple(this.name, this.label, this.intro, this.required);
		ret.disabled = this.disabled;
		ret.value = this.value;
		return ret;
	}

	getValue(): (FileList | null)[] {
		return this.value;
	}

	getValuesAsBase64(): Promise<FileBase64[] | null> {
		if (this.value === null) {
			return Promise.resolve(null);
		}

		let promises: Promise<FileBase64>[] = [];

		for (const x of this.value) {
			if (x) {
				promises = promises.concat(
					iterFiles(x)
						.map(async (file) => new FileBase64(file.name, await FieldFileMultiple.getBase64(file)))
						.toArray(),
				);
			} else {
				promises = promises.concat([]);
			}
		}

		return Promise.all(promises);
	}
}

export class FieldSelect extends Field {
	label: string;
	intro: string | null;
	options: FieldOption[];
	required: boolean;
	disabled: boolean = false;
	multiple: boolean = false;
	value: any = null;

	constructor(name: string, label: string, intro: string | null, options: FieldOption[], required: boolean) {
		super(name);

		this.label = label;
		this.intro = intro;
		this.options = options;
		this.required = required;
	}

	clone(): FieldSelect {
		const ret = new FieldSelect(
			this.name,
			this.label,
			this.intro,
			this.options.map((o) => o.clone()),
			this.required,
		);
		ret.disabled = this.disabled;
		ret.value = this.value;
		return ret;
	}

	getValue(): string | null {
		return this.value;
	}
}

export class FieldCannedResponse extends Field {
	label: string;
	intro: string | null;
	required: boolean;
	maxLen: number | null;
	hideCount: boolean;
	disabled: boolean = false;
	value: string | number | null = "";

	constructor(
		name: string,
		label: string,
		intro: string | null,
		required: boolean,
		maxLen: number | null,
		hideCount: boolean,
	) {
		super(name);

		this.label = label;
		this.intro = intro;
		this.required = required;
		this.maxLen = maxLen;
		this.hideCount = hideCount;
	}

	clone(): FieldCannedResponse {
		const ret = new FieldCannedResponse(
			this.name,
			this.label,
			this.intro,
			this.required,
			this.maxLen,
			this.hideCount,
		);
		ret.disabled = this.disabled;
		ret.value = this.value;
		return ret;
	}

	getValue(): string | number | null {
		return this.value;
	}
}

export class FieldCheckbox extends Field {
	label: string;
	intro: string | null;
	options: FieldOption[] | null;
	disabled: boolean = false;
	value: string | number | null = "";

	constructor(name: string, label: string, intro: string | null, options: FieldOption[] | null) {
		super(name);

		this.label = label;
		this.intro = intro;
		this.options = options;
	}

	clone(): FieldCheckbox {
		const ret = new FieldCheckbox(
			this.name,
			this.label,
			this.intro,
			this.options ? this.options.map((o) => o.clone()) : null,
		);
		ret.disabled = this.disabled;
		ret.value = this.value;
		return ret;
	}

	getValue(): string | number | null {
		return this.value;
	}
}

export class FieldDate extends Field {
	label: string;
	intro: string | null;
	required: boolean;
	disabled: boolean = false;
	value: string | number | null = "";
	pattern: string | number | null = null;

	constructor(name: string, label: string, intro: string | null, required: boolean) {
		super(name);

		this.label = label;
		this.intro = intro;
		this.required = required;
	}

	clone(): FieldDate {
		const ret = new FieldDate(this.name, this.label, this.intro, this.required);
		ret.disabled = this.disabled;
		ret.value = this.value;
		ret.pattern = this.pattern;
		return ret;
	}

	getValue(): string | number | null {
		return this.value;
	}
}

export class FieldDateTime extends Field {
	label: string;
	intro: string | null;
	required: boolean;
	disabled: boolean = false;
	value: string | number | null = "";
	pattern: string | number | null = null;

	constructor(name: string, label: string, intro: string | null, required: boolean) {
		super(name);

		this.label = label;
		this.intro = intro;
		this.required = required;
	}

	clone(): FieldDate {
		const ret = new FieldDate(this.name, this.label, this.intro, this.required);
		ret.disabled = this.disabled;
		ret.value = this.value;
		ret.pattern = this.pattern;
		return ret;
	}

	getValue(): string | number | null {
		return this.value;
	}
}

export class FieldRadio extends Field {
	label: string;
	intro: string | null;
	options: FieldOption[];
	disabled: boolean = false;
	value: string | number | null = "";

	constructor(name: string, label: string, intro: string | null, options: FieldOption[]) {
		super(name);

		this.label = label;
		this.intro = intro;
		this.options = options;
	}

	clone(): FieldRadio {
		const ret = new FieldRadio(
			this.name,
			this.label,
			this.intro,
			this.options.map((o) => o.clone()),
		);
		ret.disabled = this.disabled;
		ret.value = this.value;
		return ret;
	}

	getValue(): string | number | null {
		return this.value;
	}
}

export class FieldTextarea extends Field {
	label: string;
	intro: string | null;
	required: boolean;
	maxLen: number | null;
	hideCount: boolean;
	disabled: boolean = false;
	value: string | number | null = "";

	constructor(
		name: string,
		label: string,
		intro: string | null,
		required: boolean,
		maxLen: number | null,
		hideCount: boolean,
	) {
		super(name);

		this.label = label;
		this.intro = intro;
		this.required = required;
		this.maxLen = maxLen;
		this.hideCount = hideCount;
	}

	clone(): FieldTextarea {
		const ret = new FieldTextarea(this.name, this.label, this.intro, this.required, this.maxLen, this.hideCount);
		ret.disabled = this.disabled;
		ret.value = this.value;
		return ret;
	}

	getValue(): string | number | null {
		return this.value;
	}
}

export class FieldNumber extends Field {
	label: string;
	intro: string | null;
	required: boolean;
	max: number | null;
	disabled: boolean = false;
	pattern: string | null = null;
	value: string | number | null = "";

	constructor(name: string, label: string, intro: string | null, required: boolean, max: number | null) {
		super(name);

		this.label = label;
		this.intro = intro;
		this.required = required;
		this.max = max;
	}

	clone(): FieldNumber {
		const ret = new FieldNumber(this.name, this.label, this.intro, this.required, this.max);
		ret.disabled = this.disabled;
		ret.pattern = this.pattern;
		ret.value = this.value;
		return ret;
	}

	getValue(): string | number | null {
		return this.value;
	}
}

export class FieldText extends Field {
	type: string;
	label: string;
	intro: string | null;
	required: boolean;
	maxLen: number | null;
	hideCount: boolean;
	autocomplete: string | null = null;
	disabled: boolean = false;
	pattern: string | null = null;
	value: string | number | null = null;

	constructor(
		name: string,
		type: string,
		label: string,
		intro: string | null,
		required: boolean,
		maxLen: number | null,
		hideCount: boolean,
	) {
		super(name);

		this.type = type;
		this.label = label;
		this.intro = intro;
		this.required = required;
		this.maxLen = maxLen;
		this.hideCount = hideCount;
	}

	clone(): FieldText {
		const ret = new FieldText(
			this.name,
			this.type,
			this.label,
			this.intro,
			this.required,
			this.maxLen,
			this.hideCount,
		);
		ret.autocomplete = this.autocomplete;
		ret.disabled = this.disabled;
		ret.pattern = this.pattern;
		ret.value = this.value;
		return ret;
	}

	getValue(): string | number | null {
		return this.value;
	}
}

export class FieldServiceIssue extends Field {
	label: string;
	intro: string | null;
	value: any[] = [];

	constructor(name: string, label: string, intro: string | null) {
		super(name);
		this.label = label;
		this.intro = intro;
	}

	clone(): FieldServiceIssue {
		const ret = new FieldServiceIssue(this.name, this.label, this.intro);
		ret.value = this.value;
		return ret;
	}

	getValue(): any[] {
		return this.value;
	}
}

export class FieldUserAuthGroups extends Field {
	label: string;
	intro: string | null;
	value: any[] = [];

	constructor(name: string, label: string, intro: string | null) {
		super(name);
		this.label = label;
		this.intro = intro;
	}

	clone(): FieldUserAuthGroups {
		const ret = new FieldUserAuthGroups(this.name, this.label, this.intro);
		ret.value = this.value;
		return ret;
	}

	getValue(): any[] {
		return this.value;
	}
}

export class FieldTextMultiple extends Field {
	type: string;
	label: string;
	intro: string | null;
	required: boolean;
	maxLen: number | null;
	hideCount: boolean;
	autocomplete: string | null = null;
	disabled: boolean = false;
	pattern: string | null = null;
	value: string | number | null = "";

	constructor(
		name: string,
		type: string,
		label: string,
		intro: string | null,
		required: boolean,
		maxLen: number | null,
		hideCount: boolean,
	) {
		super(name);

		this.type = type;
		this.label = label;
		this.intro = intro;
		this.required = required;
		this.maxLen = maxLen;
		this.hideCount = hideCount;
	}

	clone(): FieldTextMultiple {
		const ret = new FieldTextMultiple(
			this.name,
			this.type,
			this.label,
			this.intro,
			this.required,
			this.maxLen,
			this.hideCount,
		);
		ret.autocomplete = this.autocomplete;
		ret.disabled = this.disabled;
		ret.pattern = this.pattern;
		ret.value = this.value;
		return ret;
	}

	getValue(): string | number | null {
		return this.value;
	}
}

export class FieldCompare extends Field {
	label: string;
	intro: string | null;
	disabled: boolean = false;
	value: [any, any, any] = [null, null, null];

	constructor(name: string, label: string, intro: string | null) {
		super(name);

		this.label = label;
		this.intro = intro;
	}

	clone(): FieldCompare {
		const ret = new FieldCompare(this.name, this.label, this.intro);
		ret.disabled = this.disabled;
		ret.value = this.value;
		return ret;
	}

	getValue(): [any, any, any] {
		return this.value;
	}
}

export class FieldUnknown extends Field {
	clone(): FieldUnknown {
		return new FieldUnknown(this.name);
	}

	getValue(): string {
		return "";
	}
}

export class FieldOption {
	static fromDb(stmtName: string | null, fieldName: string, row: any): FieldOption {
		return new FieldOption(row[fieldName] || row.value || row[stmtName!], row.name || row.text);
	}

	value: any;
	label: string;

	constructor(value: any, label: string) {
		this.value = value;
		this.label = label;
	}

	clone(): FieldOption {
		return new FieldOption(this.value, this.label);
	}
}

export class FileBase64 {
	constructor(public name: string, public base64: string) {}
}

<cm-label *ngIf="label && type !== 'hidden'" [forid]="id" [text]="label" [required]="required"></cm-label>
<div *ngIf="intro" [innerHTML]="intro"></div>
<input
	#input
	[placeholder]="placeholder"
	[type]="type"
	[id]="id"
	[name]="name"
	[disabled]="disabled"
	[required]="required && type !== 'hidden'"
	[attr.autocomplete]="autocomplete"
	[attr.maxlength]="type == 'hidden' ? null : maxLen"
	[attr.minlength]="type == 'hidden' ? null : minLen"
	[attr.pattern]="pattern"
	[(ngModel)]="value"
	(ngModelChange)="onModelChange()"
	[ngStyle]="inputStyle"
	class="form-control"
	(blur)="validate()"
	(focus)="focused = true"
/>
<div *ngIf="!hideCount && focused && maxLen" class="small">{{ input.value.length }} / {{ maxLen }}</div>
<div class="invalid-feedback">{{ error }}</div>

import { NgModule } from "@angular/core";
import { NumFormatPipe } from "./pipes/num-format.pipe";
import { KeyValPipe } from "./pipes/keyval.pipe";
import { JsonLdComponent } from "./components/json-ld.component";

@NgModule({
	declarations: [NumFormatPipe, KeyValPipe, JsonLdComponent],
	exports: [NumFormatPipe, KeyValPipe, JsonLdComponent],
})
export class UtilModule {}

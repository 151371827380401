import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormModule } from "../form/form.module";
import { ImageModule } from "../image/image.module";
import { LayoutModule } from "../layout/layout.module";
import { UtilModule } from "../util/util.module";
import { ColorRadioBlocksComponent } from "./components/color-radio-blocks.component";
import { CustomBuildSalespersonComponent } from "./components/custom-build-salesperson.component";
import { CustomBuildComponent } from "./components/custom-build.component";
import { ImgRadioBlocksComponent } from "./components/img-radio-blocks.component";
import { RadioBlockDirective } from "./components/radio-block.directive";
import { RadioBlocksComponent } from "./components/radio-blocks.component";

@NgModule({
	declarations: [
		CustomBuildComponent,
		RadioBlockDirective,
		RadioBlocksComponent,
		ImgRadioBlocksComponent,
		ColorRadioBlocksComponent,
		CustomBuildSalespersonComponent,
	],
	exports: [CustomBuildComponent],
	imports: [
		CommonModule,
		FontAwesomeModule,
		FormModule,
		FormsModule,
		ReactiveFormsModule,
		ImageModule,
		LayoutModule,
		NgbModule,
		UtilModule,
	],
})
export class CustomBuildModule {}

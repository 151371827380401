import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { UserService } from "@core/app/services/user.service";
import { parse } from "date-fns";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, combineLatest, of } from "rxjs";
import { concat, filter, first, map, shareReplay, switchMap, tap } from "rxjs/operators";
import { iter, SMap, tuple } from "shared/common";
import { FieldOption } from "../form/field";
import { GTMService } from "../services/gtm.service";
import { CateringMenuService } from "./catering-menu.service";

@Injectable({ providedIn: "root" })
export class EventService {
	private updateBS = new BehaviorSubject<void>(undefined);
	private updateItemsBS = new BehaviorSubject<void>(undefined);

	activeOrderIdBS: BehaviorSubject<any> = new BehaviorSubject(undefined);

	eventInfo$ = this.user.user$.pipe(
		switchMap((user) =>
			user ? combineLatest(this.updateBS.pipe(map(() => true)), this.activeOrderIdBS) : of([false]),
		),
		switchMap(([sendReq, activeOrderIdBS]) =>
			sendReq
				? of(null).pipe(
						concat(
							this.http
								.post("api/statement/GetEventInfo", { vars: { orderid: activeOrderIdBS } })
								.pipe(map((res: any) => res.results)),
						),
				  )
				: of([]),
		),
		map((results: any) => {
			if (!results) {
				return null;
			} else if (!results[0]) {
				return { start: null, end: null };
			}

			const ret = results[0];
			return {
				orderid: ret.orderid,
				start: ret.event_date && parse(`${ret.event_date} ${ret.start_time}`),
				end: ret.event_date && parse(`${ret.event_date} ${ret.end_time}`),
				guests: ret.guests,
				event_date: ret.event_date,
				event_locid: ret.event_locid,
				order_status: ret.order_status,
				event_name: ret.event_name,
				food_serv_typeid: ret.food_serv_typeid,
				food_serv_type: ret.food_serv_type,
				special_requests: ret.special_requests,
				order_statusid: ret.order_statusid,
			};
		}),
		shareReplay(1),
	);

	locked$ = combineLatest(this.user.permissions$, this.eventInfo$).pipe(
		map(
			([perms, eventInfo]) =>
				!perms.hasPermission(["catering", "manager"]) &&
				!!eventInfo &&
				eventInfo.order_status &&
				eventInfo.order_status !== "New",
		),
		shareReplay(1),
	);

	eventInfos$ = this.user.user$.pipe(
		switchMap((user) => (user ? this.updateBS.pipe(map(() => true)) : of(false))),
		switchMap((sendReq) =>
			sendReq
				? of(null).pipe(
						concat(this.http.post("api/statement/GetEventInfo", {}).pipe(map((res: any) => res.results))),
				  )
				: of([]),
		),
		map((results: any) => {
			if (!results) {
				// loading
				return null;
			} else if (!results[0]) {
				// not logged in, or no event created yet
				return { start: null, end: null };
			}

			return results.map((row: any) => ({
				orderid: row.orderid,
				start: row.event_date && parse(`${row.event_date} ${row.start_time}`),
				end: row.event_date && parse(`${row.event_date} ${row.end_time}`),
				guests: row.guests,
				event_date: row.event_date,
				event_locid: row.event_locid,
				order_status: row.order_status,
				event_name: row.event_name,
				food_serv_typeid: row.food_serv_typeid,
				special_requests: row.special_requests,
				order_statusid: row.order_statusid,
			}));
		}),
		shareReplay(1),
	);

	allEventItems$ = this.updateItemsBS.pipe(
		switchMap(() => this.eventInfo$),
		filter((event) => !!event),
		switchMap((event: any) => this.http.post("/api/statement/GetEventItems", { vars: { orderid: event.orderid } })),
		map((res: any) => res.results),
		shareReplay(1),
	);

	eventItems$ = this.allEventItems$.pipe(
		map((results) => {
			const byId = iter(results)
				.map((row: any) => tuple(row.offerid, row))
				.toMap();
			const ret = iter(results)
				.filter((row: any) => row.qty)
				.filter((row: any) => {
					while (row.pkg_offerid) {
						row = byId.get(row.pkg_offerid);
						if (!row) {
							return false;
						}
					}
					return true;
				})
				.map((row: any) => tuple(row.offer_cat, { ...row }))
				.toGroupMap()
				.toMap();
			const subitemCounts = new SMap<number, number>();
			for (const val of iter(ret.values()).flatten()) {
				// add price for "extra" items
				const parent = byId.get(val.pkg_offerid);
				if (parent && parent.offer_extra_price) {
					const count = subitemCounts.getOrInsert(parent.offerid, 0) + 1;
					subitemCounts.set(parent.offerid, count);
					if (count > parent.of_qty) {
						val.offer_price = Number(val.offer_price) + Number(parent.offer_extra_price);
					}
				}
			}
			return ret;
		}),
		shareReplay(1),
	);

	eventItemsUnfiltered$ = this.allEventItems$.pipe(
		map((results) => {
			const byId = iter(results)
				.map((row: any) => tuple(row.offerid, row))
				.toMap();
			const ret = iter(results)
				.filter((row: any) => {
					while (row.pkg_offerid) {
						row = byId.get(row.pkg_offerid);
						if (!row) {
							return false;
						}
					}
					return true;
				})
				.map((row: any) => tuple(row.offer_cat, { ...row }))
				.toGroupMap()
				.toMap();
			const subitemCounts = new SMap<number, number>();
			for (const val of iter(ret.values()).flatten()) {
				// add price for "extra" items
				const parent = byId.get(val.pkg_offerid);
				const supergroup = parent && byId.get(parent.pkg_offerid);
				const superparent = supergroup || parent;
				if (superparent && superparent.offer_extra_price) {
					const count = subitemCounts.getOrInsert(superparent.offerid, 0) + 1;
					subitemCounts.set(superparent.offerid, count);
					if (count > superparent.of_qty) {
						val.offer_price = Number(val.offer_price) + Number(superparent.offer_extra_price);
					}
				}
			}
			return ret;
		}),
		shareReplay(1),
	);

	allergens$ = this.eventInfo$.pipe(
		filter((eventInfo) => !!eventInfo),
		switchMap((eventInfo: any) =>
			this.http.post("/api/statement/GetAllergen", { vars: { orderid: eventInfo.orderid } }).pipe(
				map((res: any) =>
					res.results.map((row: any) => ({
						allergenid: row.allergenid,
						allergen: row.allergen,
						checked: row.order_allergenid !== null,
					})),
				),
				shareReplay(1),
			),
		),
	);

	eventItemMap$ = this.eventItems$.pipe(
		map((items) =>
			iter(items.values())
				.flatten()
				.map((x: any) => tuple(x.offerid, x))
				.toMap(),
		),
		shareReplay(1),
	);
	eventItemMapUnfiltered$ = this.eventItemsUnfiltered$.pipe(
		map((items) =>
			iter(items.values())
				.flatten()
				.map((x: any) => tuple(x.offerid, { ...x, children: [] }))
				.toMap(),
		),
		shareReplay(1),
	);

	eventItemTree$ = combineLatest(this.eventItemMapUnfiltered$, this.menu.itemMap$).pipe(
		map(([eventItemMap, itemMap]) => {
			const roots: any[] = [];
			for (const item of eventItemMap.values()) {
				const list = (() => {
					if (item.pkg_offerid) {
						const parent = eventItemMap.get(item.pkg_offerid);
						if (parent) {
							return parent.children;
						} else {
							return null;
						}
					} else {
						return roots;
					}
				})();
				if (list) {
					list.push(item);
				}
			}
			for (const item of eventItemMap.values()) {
				if (!item.children.length && itemMap.get(item.offerid).children.length) {
					const children = eventItemMap.get(item.pkg_offerid).children;
					children.splice(children.indexOf(item), 1);
				}
			}
			return roots;
		}),
		shareReplay(1),
	);

	itemCost$ = combineLatest(this.eventItemMapUnfiltered$, this.eventInfo$).pipe(
		filter(([_, eventInfo]) => !!eventInfo),
		map(([items, eventInfo]) => {
			let totalCost = 0;
			for (const item of items.values()) {
				const parent = items.get(item.pkg_offerid);
				if (item.offer_cat.includes("Package") || (parent && parent.offer_cat === "Package Group")) {
					totalCost += Number(item.offer_price) * eventInfo!.guests!;
				} else {
					totalCost += Number(item.offer_price) * item.qty;
				}
			}
			return totalCost;
		}),
		shareReplay(1),
	);

	platedFee$ = this.eventInfo$.pipe(
		filter((eventInfo) => !!eventInfo),
		map((eventInfo) => {
			if (eventInfo!.food_serv_typeid === 3 || eventInfo!.food_serv_typeid === 4) {
				return 2 * Number(eventInfo!.guests);
			}
			return 0;
		}),
		shareReplay(1),
	);

	serviceFee$ = combineLatest(this.eventInfo$, this.itemCost$, this.platedFee$).pipe(
		filter(([eventInfo]) => !!eventInfo),
		map(([eventInfo, cost, platedFee]) => {
			if (eventInfo!.food_serv_typeid !== 1) {
				return (cost + platedFee) * 0.2;
			} else {
				return 0;
			}
		}),
		shareReplay(1),
	);

	taxes$ = combineLatest(this.itemCost$, this.serviceFee$).pipe(
		map(([cost, fee]) => (cost + fee) * 0.06),
		shareReplay(1),
	);

	eventCost$ = combineLatest(this.itemCost$, this.taxes$, this.serviceFee$).pipe(
		map(([cost, taxes, serviceFee]) => cost + taxes + serviceFee),
		shareReplay(1),
	);

	costPerGuest$ = combineLatest(this.eventInfo$, this.eventCost$).pipe(
		map(([eventInfo, eventCost]) => (eventInfo && eventCost ? eventCost / eventInfo!.guests : 0)),
		shareReplay(1),
	);

	salesCoordinators$ = this.http.post("api/statement/GetCateringSalesCoordinators", {}).pipe(
		map((res: any) => res.results),
		shareReplay(1),
	);

	spaces$ = this.http.post("/api/statement/GetEventLocations", {}).pipe(
		map((response: any) => {
			const locations: any = { harrisSpaces: [], partnerSpaces: [] };
			for (const location of response.results) {
				const obj = {
					...location,
					images: location.featureImgs ? location.featureImgs.split(",") : [],
					lat: parseFloat(location.latitude),
					lng: parseFloat(location.longitude),
				};
				if (location.site_locationid) {
					locations.harrisSpaces.push(obj);
				} else {
					locations.partnerSpaces.push(obj);
				}
			}
			return locations;
		}),
		shareReplay(1),
	);

	serviceStyles$ = this.http.post("/api/statement/GetServiceStyles", {}).pipe(
		map((res: any) => res.results),
		shareReplay(1),
	);

	statuses$ = this.http
		.post("/api/statement/GetOrderStatuses", {})
		.pipe(
			map((response: any) =>
				response.results.map((row: any) => new FieldOption(row.order_statusid, row.order_status)),
			),
		);

	constructor(
		private http: HttpClient,
		private user: UserService,
		private router: Router,
		private toastrService: ToastrService,
		private menu: CateringMenuService,
		private gtmService: GTMService,
	) {}

	update(props: any) {
		return this.eventInfo$.pipe(
			filter((x) => !!x),
			first(),
			switchMap((info: any) => {
				if (props.event_locid && info.food_serv_typeid === 1) {
					this.toastrService.error("Unable to set Venue with Pick up/Delivery Selected", "Error Updating");
					return of();
				} else {
					return this.http.post("api/catering/update-event", { orderid: info.orderid, ...props });
				}
			}),
			tap(() => this.updateBS.next()),
		);
	}

	create() {
		return this.http
			.post("api/catering/update-event", {})
			.pipe(tap((res: any) => this.activeOrderIdBS.next(res.orderid)));
	}

	addItem(offerid: number, subOfferids: Map<number, number>, qty: number = 1, notes?: string) {
		return this.eventInfo$.pipe(
			filter((x) => !!x),
			first(),
			switchMap((info: any) =>
				this.http.post("api/catering/add-order-item", {
					orderid: info.orderid,
					offerid,
					subOfferids: iter(subOfferids)
						.map(([key, value]) => ({ id: key, qty: value }))
						.toArray(),
					qty,
					notes,
				}),
			),
			tap(() => this.updateItemsBS.next()),
		);
	}
	addPackage(offerid: number, subOfferids: number[], qty: number = 1, notes?: string) {
		return this.eventInfo$.pipe(
			filter((x) => !!x),
			first(),
			switchMap((info: any) =>
				this.http.post("api/catering/add-order-item", {
					orderid: info.orderid,
					offerid,
					subOfferids,
					qty,
					notes,
				}),
			),
			tap(() => this.updateItemsBS.next()),
		);
	}
	updateItem(item: any) {
		return this.http
			.post("/api/statement/UpdateOrderItem", { vars: item })
			.pipe(tap(() => this.updateItemsBS.next()));
	}

	changeActiveOrder(orderid: number) {
		this.activeOrderIdBS.next(orderid);
	}

	sendOrder(orderid: number) {
		this.http.post("api/catering/send-order", { orderid }).subscribe(() => {
			this.gtmService.track("Event Submitted", "success", "Order ID: " + orderid);
			this.toastrService.success("Our Event Staff Will Be In Contact", "Submit Successful");
		});
	}

	duplicate(orderid: number) {
		this.http.post("api/catering/duplicate-event", { orderid }).subscribe((result: any) => {
			this.gtmService.track("Event Duplicated", "success", "Order ID: " + orderid);
			this.activeOrderIdBS.next(result.orderid);
			this.router.navigateByUrl("event-planner");
		});
	}

	deleteItem(offerid: number) {
		this.eventInfo$
			.pipe(
				filter((info) => !!info),
				first(),
				switchMap((info) =>
					this.http.post("/api/catering/del-order-item", {
						orderid: info!.orderid,
						offerid,
					}),
				),
			)
			.subscribe(() => this.updateItemsBS.next());
	}

	updateSpecialRequests(special_requests: string, orderid: number) {
		this.http.post("/api/statement/UpdateSpecialRequest", { vars: { special_requests, orderid } }).subscribe();
	}

	updateTitle(title: string, orderid: number) {
		this.http.post("/api/statement/UpdateTitle", { vars: { title, orderid } }).subscribe();
	}

	updateStatus(order_statusid: number, orderid: number) {
		this.http.post("/api/statement/UpdateStatus", { vars: { order_statusid, orderid } }).subscribe();
	}

	addAllergen(allergenid: number, orderid: number) {
		this.http.post("/api/statement/InsertOrderAllergen", { vars: { allergenid, orderid } }).subscribe();
	}

	removeAllergen(allergenid: number, orderid: number) {
		this.http.post("/api/statement/DeleteOrderAllergen", { vars: { allergenid, orderid } }).subscribe();
	}
}

import { CommonModule, Location } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { ModalFileUploadComponent } from "@core/app/admin/components/modal-file-upload/modal-file-upload.component";
import { AutocompleteComponent } from "@core/app/autocomplete/autocomplete.component";
import { BootstrapModule } from "@core/app/bootstrap/bootstrap.module";
import { ButtonModule } from "@core/app/button/button.module";
import { ClickCopyDirective } from "@core/app/directive/click-copy.directive";
import { DropzoneComponent } from "@core/app/dropzone/dropzone.component";
import { FacebookCommentComponent } from "@core/app/facebook-comment/facebook-comment.component";
import { FaqModule } from "@core/app/faq/faq.module";
import { FormModule } from "@core/app/form/form.module";
import { ImageModule } from "@core/app/image/image.module";
import { InventoryModule } from "@core/app/inventory/inventory.module";
import { LayoutModule } from "@core/app/layout/layout.module";
import { LazyModule } from "@core/app/lazy/lazy.module";
import { LoginComponent } from "@core/app/login/login.component";
import { Map2Module } from "@core/app/map2/map2.module";
import { Pagination2Module } from "@core/app/pagination2/pagination2.module";
import { PaymentCalculatorComponent } from "@core/app/payment-calculator/payment-calculator.component";
import { GroupByPipe } from "@core/app/pipes/group-by.pipe";
import { PromotionBannerComponent } from "@core/app/promotion/components/promotion-banner/promotion-banner.component";
import { ReviewsModule } from "@core/app/reviews/reviews.module";
import { SearchModule } from "@core/app/search/search.module";
import { SalesStaffComponent } from "@core/app/shared/components/sales-staff/sales-staff.component";
import { SocialLinks2Component } from "@core/app/shared/components/social-links2/social-links2.component";
import { SliderModule } from "@core/app/slider/slider.module";
import { SocialLoginComponent } from "@core/app/social-login/social-login.component";
import { TableModule } from "@core/app/table/table.module";
import { TimeClockComponent } from "@core/app/time-clock/time-clock.component";
import { VideoModule } from "@core/app/video/video.module";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FlatpickrModule } from "angularx-flatpickr";
import { BrandModule } from "../brand/brand.module";
import { CardGridComponent } from "../card-grid/card-grid.component";
import { CardComponent } from "../card/card.component";
import { CateringModule } from "../catering/catering.module";
import { CustomBuildModule } from "../custom-build/custom-build.module";
import { EventTypeSliderComponent } from "../event-type-slider/event-type-slider.component";
import { ListingModule } from "../listing/listing.module";
import { MfrModule } from "../mfr/mfr.module";
import { ModelNumModule } from "../model-num/model-num.module";
import { SafePipe } from "../pipes/safe.pipe";
import { ReviewSliderComponent } from "../review-slider/review-slider.component";
import { UtilModule } from "../util/util.module";

@NgModule({
	declarations: [
		AutocompleteComponent,
		ClickCopyDirective,
		FacebookCommentComponent,
		GroupByPipe,
		LoginComponent,
		PaymentCalculatorComponent,
		SocialLoginComponent,
		TimeClockComponent,
		DropzoneComponent,
		ModalFileUploadComponent,
		SocialLinks2Component,
		PromotionBannerComponent,
		SalesStaffComponent,
		CardComponent,
		CardGridComponent,
		SafePipe,
		EventTypeSliderComponent,
		ReviewSliderComponent,
	],
	imports: [
		BootstrapModule,
		CommonModule,
		FontAwesomeModule,
		FormsModule,
		NgbModule,
		FlatpickrModule.forRoot(),
		RouterModule,
		LazyModule,
		FaqModule,
		RouterModule,
		ImageModule,
		ButtonModule,
		Map2Module,
		InventoryModule,
		SearchModule,
		SliderModule,
		LayoutModule,
		Pagination2Module,
		ReviewsModule,
		FormModule,
		VideoModule,
		TableModule,
		ListingModule,
		BrandModule,
		MfrModule,
		ModelNumModule,
		UtilModule,
		CustomBuildModule,
		CateringModule,
	],
	exports: [
		AutocompleteComponent,
		BootstrapModule,
		ButtonModule,
		ClickCopyDirective,
		CommonModule,
		FacebookCommentComponent,
		FontAwesomeModule,
		FormsModule,
		GroupByPipe,
		LoginComponent,
		NgbModule,
		PaymentCalculatorComponent,
		RouterModule,
		SocialLoginComponent,
		TimeClockComponent,
		DropzoneComponent,
		FlatpickrModule,
		LazyModule,
		FaqModule,
		ImageModule,
		ModalFileUploadComponent,
		SocialLinks2Component,
		Map2Module,
		InventoryModule,
		SearchModule,
		SliderModule,
		LayoutModule,
		Pagination2Module,
		PromotionBannerComponent,
		SalesStaffComponent,
		ReviewsModule,
		FormModule,
		VideoModule,
		TableModule,
		ListingModule,
		BrandModule,
		MfrModule,
		ModelNumModule,
		CustomBuildModule,
		CardComponent,
		CardGridComponent,
		SafePipe,
		EventTypeSliderComponent,
		ReviewSliderComponent,
		CateringModule,
	],
	providers: [Location],
})
export class SharedModule {}

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BgComponent } from "./components/bg.component";
import { TintComponent } from "./components/tint.component";
import { SlugifyPipe } from "../pipes/slugify.pipe";

@NgModule({
	declarations: [BgComponent, TintComponent, SlugifyPipe],
	imports: [CommonModule],
	exports: [BgComponent, TintComponent, SlugifyPipe],
})
export class LayoutModule {}

import { Component, Inject } from "@angular/core";
import { IPageData } from "@model/page-data";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";

@Component({
	selector: "cm-footer",
	template: `
		<footer class="footer d-flex flex-wrap justify-content-center align-items-center mt-5">
			<div class="d-flex align-items-center justify-content-center footer-left flex-wrap flex-grow-1">
				<img [src]="logoUrl | image: 't'" [alt]="logoAlt" class="harris-logo flex-grow-0 px-sm-5 px-2 my-3" />
				<div class="d-flex text-nowrap flex-wrap justify-content-center">
					<div class="d-flex flex-column px-5 my-3">
						<div *ngFor="let emp of salesCoordinators$ | async">
							<div>{{ emp.org_dept_position }}</div>
							<h4 class="mb-1 mt-2">{{ emp.first_name }} {{ emp.last_name }}</h4>
							<a href="mailto:{{ emp.user_email }}">{{ emp.user_email }}</a>
						</div>
					</div>
					<div class="terms px-sm-5 px-2 my-3">
						<div class="mb-3">
							<a routerLink="/terms-of-service">Terms of Service</a> |
							<a routerLink="/privacy-policy">Privacy Policy</a> | <a routerLink="/jobs">Jobs</a>
						</div>
						Powered by <a href="https://designforcemarketing.com">Design Force Marketing</a>
					</div>
				</div>
			</div>
			<div class="flex-grow-1 d-flex justify-content-center mx-2 my-2 footer-right">
				<div>
					More Great Venues from Harris Hospitality
					<div class="text-center">
						<a href="https://pintsandquarts.com/" target="_blank" class="footer-logo">
							<img
								src="https://dms.rvimg.com/s3static/18/pints-and-quarts-logo-web-safe-e1555082223188.png"
								alt="Pints & Quarts"
						/></a>
						<a href="https://thedeckmkg.com/" target="_blank" class="footer-logo">
							<img src="https://dms.rvimg.com/s3static/18/thedeck-logo-rgb.png" alt="The Deck"
						/></a>
					</div>
				</div>
			</div>
		</footer>
	`,
	styles: [
		`
			.footer {
				height: 430px;
			}
			a:hover {
				color: #557f2e;
			}
			.footer-logo img {
				max-height: 100px;
				margin: 20px;
				max-width: 200px;
			}
			@media (min-width: 1000px) {
				.footer-left {
					min-width: 1000px;
				}
			}
			@media (max-width: 992px) {
				.footer-right {
					text-align: center;
				}
			}
			@media print {
				.footer {
					display: none !important;
				}
			}
		`,
	],
})
export class FooterComponent {
	logoAlt: string;
	logoUrl: string;

	salesCoordinators$ = this.http
		.post("api/statement/GetCateringSalesCoordinators", {})
		.pipe(map((res: any) => res.results));

	constructor(private http: HttpClient, @Inject("PAGE_DATA") pageData: IPageData) {
		const dealerInfo = pageData.headerData.queries.GetDealerInfo[0];
		this.logoAlt = dealerInfo.img_alt;
		this.logoUrl = dealerInfo.img_dir + dealerInfo.img_file;
	}
}

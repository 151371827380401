import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { AdwordsModule } from "@core/app/adwords/adwords.module";
import { ImageModule } from "@core/app/image/image.module";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ButtonModule } from "@core/app/button/button.module";
import { FormModule } from "@core/app/form/form.module";
import { TableFiltersComponent } from "./components/table-filters/table-filters.component";
import { TableSelectsComponent } from "./components/table-selects/table-selects.component";
import { TableComponent } from "./components/table/table.component";

@NgModule({
	declarations: [TableComponent, TableFiltersComponent, TableSelectsComponent],
	imports: [
		CommonModule,
		FontAwesomeModule,
		AdwordsModule,
		ButtonModule,
		FormModule,
		NgbModule,
		ImageModule,
		RouterModule,
		FormsModule,
	],
	exports: [TableComponent, TableFiltersComponent, TableSelectsComponent],
})
export class TableModule {}

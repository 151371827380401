import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, SimpleChanges } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ImagePipe } from "@core/app/image/pipes/image.pipe";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, combineLatest, Subject } from "rxjs";
import { filter, first, map, scan, share, shareReplay, startWith, switchMap, tap } from "rxjs/operators";
import { iter, Iter, SMap, tuple } from "shared/common";
import { CateringMenuService } from "../catering-menu.service";
import { EventService } from "../event.service";

const serviceFilter = new Map([
	[3, new Set([2, 178, 300, 343])],
	[4, new Set([2, 178, 300, 343])],
]);

const cocktailCats = ["Platters", "Hors d'Oeuvres", "Appetizer Dips"];

const popupChains = new Map([
	[
		1,
		[
			{ title: "Would you like to add appetizers?", cats: cocktailCats },
			{ title: "Would you like to add desserts?", cats: ["Desserts"], offerCat: "Dessert Package" },
			{ title: "Would you like to add dinnerware?", cats: ["Extras"] },
		],
	],
	[
		2,
		[
			{ title: "Would you like to add appetizers?", cats: cocktailCats },
			{ title: "Would you like to add carving stations?", offerCat: "Carving Station Package" },
			{ title: "Would you like to add desserts?", cats: ["Desserts"], offerCat: "Dessert Package" },
			{ title: "Would you like to add drinks?", offerCat: "Bar Package" },
			{ title: "Would you like to add dinnerware?", cats: ["Extras"] },
		],
	],
	[
		3,
		[
			{ title: "Would you like to add appetizers?", cats: cocktailCats },
			{ title: "Would you like to add desserts?", cats: ["Desserts"], offerCat: "Dessert Package" },
			{ title: "Would you like to add drinks?", offerCat: "Bar Package" },
			{ title: "Would you like to add dinnerware?", cats: ["Extras"] },
		],
	],
	[
		4,
		[
			{ title: "Would you like to add appetizers?", cats: cocktailCats },
			{ title: "Would you like to add desserts?", cats: ["Desserts"], offerCat: "Dessert Package" },
			{ title: "Would you like to add drinks?", offerCat: "Bar Package" },
			{ title: "Would you like to add dinnerware?", cats: ["Extras"] },
		],
	],
	[
		5,
		[
			{ title: "Would you like to add desserts?", cats: ["Desserts"], offerCat: "Dessert Package" },
			{ title: "Would you like to add carving stations?", offerCat: "Carving Station Package" },
			{ title: "Would you like to add drinks?", offerCat: "Bar Package" },
			{ title: "Would you like to add dinnerware?", cats: ["Extras"] },
		],
	],
]);

const blacklist = new Map([
	[1, new Set([343, 803])],
	[3, new Set([343, 803])],
	[4, new Set([343, 803])],
]);

@Component({
	selector: "cm-catering-packages",
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<cm-catering-service *ngIf="showServiceTypes" (saved)="packageIdxBS.next(0)"></cm-catering-service>

		<ng-container *ngIf="event.eventInfo$ | async as eventInfo">
			<div
				*ngIf="eventInfo!.food_serv_typeid && (offer_cat !== 'Package' || eventInfo!.food_serv_typeid !== 5)"
				class="container-fluid px-xl-5"
			>
				<ng-container *ngIf="packages$ | async as packages">
					<div *ngIf="packages!.length > 1" class="pt-sm-0 pt-3">
						<h2>{{ title1 }}</h2>
						<cm-catering-cats
							[cats]="offersAsCats(packages)"
							[catIndex]="packageIdxBS | async"
							(catIndexChange)="packageIdxBS.next($event); levelIdxBS.next(0)"
							class="package"
						>
							<ng-template cmCateringCat *ngFor="let pkg of packages" let-img="img">
								<div class="text-center text-white">
									<div class="h5 mb-0 text-white text-shadow">
										{{ pkg.offer }}
									</div>
									<ng-container *ngIf="pkg.offer_price">
										{{ pkg.offer_price | currency: undefined:undefined:"1.0-2" }}/pp
									</ng-container>
								</div>
							</ng-template>
						</cm-catering-cats>
					</div>
				</ng-container>

				<ng-container *ngIf="levels$ | async as levels">
					<div *ngIf="levels.length > 1">
						<h2 class="mt-3">Choose a Package</h2>
						<cm-catering-cats
							[cats]="offersAsCats(levels)"
							[catIndex]="levelIdxBS | async"
							(catIndexChange)="levelIdxBS.next($event)"
							[border]="false"
							[grayscale]="false"
						>
							<ng-template cmCateringCat *ngFor="let level of levels" let-img="img">
								<div class="text-center" [ngClass]="{ 'text-white': !img }">
									<div class="h5 mb-0" [ngClass]="{ 'text-white': !img }">
										{{ level.offer }}
									</div>
									<ng-container *ngIf="level.offer_price">
										{{ level.offer_price | currency: undefined:undefined:"1.0-2" }}/pp
									</ng-container>
								</div>
							</ng-template>
						</cm-catering-cats>
					</div>
				</ng-container>

				<h3 *ngIf="!hideTitles" class="my-3">
					{{ (package$ | async)?.offer }}
					<ng-container *ngIf="level$ | async as level">- {{ level.offer }}</ng-container>
				</h3>
				<div class="row mb-3">
					<ng-container *ngFor="let group of groups$ | async" class="col-12 mt-3">
						<ng-container *ngIf="group.offer_cat === 'Package Supergroup'">
							<ng-container
								*ngTemplateOutlet="supergroupTpl; context: { supergroup: group }"
							></ng-container>
						</ng-container>
					</ng-container>
					<div
						*ngFor="let group of applyServiceFilter(eventInfo, groups$ | async)"
						class="col-lg-4 col-md-6 col-sm-12 mt-3"
						[ngClass]="{ 'col-xl-4': inModal, 'col-xl-3': !inModal }"
					>
						<ng-container *ngIf="group.offer_cat !== 'Package Supergroup'">
							<ng-container
								*ngTemplateOutlet="groupTpl; context: { group: group, supergroup: null, img: true }"
							></ng-container>
						</ng-container>
					</div>

					<ng-template #supergroupTpl let-supergroup="supergroup">
						<div class="border border-dark col-12 py-3 mb-3">
							<div class="text-center">
								<h4 class="mb-0">{{ supergroup.offer }}</h4>
								<h5 class="mb-0" *ngIf="supergroup.offer_desc">{{ supergroup.offer_desc }}</h5>
								<ng-container *ngIf="supergroup.offer_price && supergroup.hide_price === 0">
									{{ supergroup.offer_price | currency: undefined:undefined:"1.0-2" }}/pp
								</ng-container>
								<div class="note text-uppercase">
									<ng-container *ngIf="supergroup.of_qty">
										Choose {{ supergroup.of_qty }}
									</ng-container>
									<ng-container *ngIf="supergroup.offer_extra_price">
										<br />
										Additional +{{
											supergroup.offer_extra_price | currency: undefined:undefined:"1.0-2"
										}}/pp
									</ng-container>
								</div>
							</div>
							<div class="row">
								<ng-container *ngFor="let group of applyServiceFilter(eventInfo, supergroup.children)">
									<div
										*ngIf="!blacklist.get(eventInfo.food_serv_typeid)?.has(group.offerid)"
										class="col-xl-3 col-lg-4 col-md-6 col-sm-12 mt-3"
									>
										<ng-container
											*ngTemplateOutlet="
												groupTpl;
												context: { group: group, supergroup: supergroup, img: true }
											"
										></ng-container>
									</div>
								</ng-container>
							</div>
						</div>
					</ng-template>

					<ng-template #groupTpl let-group="group" let-supergroup="supergroup" let-img="img">
						<div class="embed-responsive embed-responsive-16by9 border-heavy shadow">
							<img
								[src]="
									hoverImg.get(group.offerid) || group.image || 's3static/18/generic-menu.jpg'
										| image: 'm'
								"
								[alt]="group.offer"
								class="embed-responsive-item item-img"
							/>
						</div>
						<div class="group-name mt-3">{{ group.offer }}</div>
						<div class="text-muted">{{ group.offer_desc }}</div>
						<div *ngIf="!supergroup" class="note text-uppercase">
							<span *ngIf="!group.of_qty; else choose" class="text-muted">Included</span>
							<ng-template #choose>
								<ng-container *ngIf="group.of_qty < group.children.length">
									Choose {{ group.of_qty }}
									<ng-container *ngIf="group.offer_price && group.hide_price === 0">
										- {{ group.offer_price | currency: undefined:undefined:"1.0-2" }}/pp
									</ng-container>
									<ng-container *ngIf="group.offer_extra_price">
										<br />
										Additional +{{
											group.offer_extra_price | currency: undefined:undefined:"1.0-2"
										}}/pp
									</ng-container>
								</ng-container>
							</ng-template>
						</div>

						<ng-container *ngIf="activeItems$ | async as activeItems">
							<div
								*ngFor="let item of group.children"
								class="form-check"
								(mouseover)="
									hoverImg.set(
										group.offerid,
										item.image || group.image || 's3static/18/generic-menu.jpg'
									)
								"
							>
								<input
									type="checkbox"
									id="offer-{{ item.offerid }}"
									class="form-check-input"
									[disabled]="
										(event.locked$ | async) || checkDisabled(group, supergroup, activeItems, item)
									"
									[ngModel]="activeItems.active.has(item.offerid)"
									(ngModelChange)="checkS.next([item, $event])"
								/>
								<label for="offer-{{ item.offerid }}" class="form-check-label">
									{{ item.offer }}
									<ng-container *ngIf="item.offer_desc">with {{ item.offer_desc }}</ng-container>
									<span *ngIf="item.offer_price > 0">
										+{{ item.offer_price | currency: undefined:undefined:"1.0-2" }}/pp
									</span>
								</label>
							</div>

							<ng-container *ngFor="let item of group.children">
								<div *ngIf="activeItems.active.has(item.offerid)" class="row">
									<div
										*ngFor="let group of item.children"
										class="col-{{ 12 / item.children.length }}"
									>
										<ng-container
											*ngTemplateOutlet="groupTpl; context: { group: group, img: false }"
										></ng-container>
									</div>
								</div>
							</ng-container>
						</ng-container>
					</ng-template>
				</div>

				<div class="row py-4 my-3" *ngIf="!(event.locked$ | async)">
					<div class="col-12 col-lg-6 d-flex">
						<button
							*ngIf="package$ | async as package"
							type="button"
							class="btn btn-lg btn-primary flex-grow-1"
							[disabled]="savingBS | async"
							(click)="submit()"
						>
							{{
								(event.eventItemMap$ | async)?.has((package$ | async).offerid) ? updateText : submitText
							}}
						</button>
					</div>
				</div>
			</div>

			<div *ngIf="offer_cat === 'Package' && eventInfo!.food_serv_typeid === 5" class="container-fluid px-xl-5">
				<cm-catering-menu [useCats]="cocktailCats" class="pt-3 pb-4"></cm-catering-menu>
				<div class="row py-4 my-3" *ngIf="!(event.locked$ | async)">
					<div class="col-12 col-lg-6 d-flex">
						<button type="button" class="btn btn-lg btn-primary flex-grow-1" (click)="submitCocktail()">
							Continue
						</button>
					</div>
				</div>
			</div>
		</ng-container>

		<cm-login-modal [(show)]="showLogin" (loggedIn)="showLogin = false; submit()"></cm-login-modal>

		<ng-container *ngIf="(popupIndexBS | async) !== null">
			<ng-container *ngIf="popupChain[popupIndexBS | async] as popupInfo">
				<cm-catering-packages-modal
					*ngIf="popupInfo.offerCat"
					[offerCat]="popupInfo.offerCat"
					[looseCats]="popupInfo.cats"
					[title]="popupInfo.title"
					[showBack]="(popupIndexBS | async) > 0"
					(back)="popupIndexBS.next(popupIndexBS.value - 1)"
					(offerCatChange)="popupIndexBS.next(popupIndexBS.value + 1)"
				></cm-catering-packages-modal>
				<cm-catering-menu-modal
					*ngIf="!popupInfo.offerCat"
					[cats]="popupInfo.cats"
					[title]="popupInfo.title"
					[showBack]="(popupIndexBS | async) > 0"
					(back)="popupIndexBS.next(popupIndexBS.value - 1)"
					(catsChange)="popupIndexBS.next(popupIndexBS.value + 1)"
				></cm-catering-menu-modal>
			</ng-container>
		</ng-container>
	`,
	styles: [
		`
			:host {
				display: block;
			}
			#guest-count {
				font-size: 1rem;
				width: 80px;
			}
			h3,
			h2 {
				line-height: 1;
			}
			@media (max-width: 767px) {
				h3,
				h2 {
					font-size: 2.2rem;
				}
			}

			.note {
				letter-spacing: 2px;
			}

			.group-name {
				font-size: 1.5rem;
				line-height: 1;
			}

			.item-img {
				object-fit: cover;
			}
		`,
	],
})
export class CateringPackagesComponent {
	@Input() hideTitles: boolean = false;
	@Input() title1: string = "What are you looking for?";
	@Input() offer_cat: string = "Package";
	@Input() submitText: string = "Add to Event";
	@Input() updateText: string = "Update Package";
	@Input() showServiceTypes: boolean = true;
	@Input() inModal: boolean = false;

	@Output() submitted = new EventEmitter<void>();

	blacklist = blacklist;
	cocktailCats = cocktailCats;
	serviceFilter = serviceFilter;

	packageIdxBS = new BehaviorSubject(0);
	levelIdxBS = new BehaviorSubject(0);
	savingBS = new BehaviorSubject(false);
	showLogin = false;
	checkS = new Subject<[any, boolean]>();
	hoverImg = new Map<number, string>();
	popupIndexBS = new BehaviorSubject<number | null>(null);
	popupChain: any = null;
	popupClosed = new Subject<void>();
	offer_catBS = new BehaviorSubject("Package");

	packages$ = combineLatest(this.event.eventInfo$, this.menu.itemTree$, this.offer_catBS).pipe(
		map(([eventInfo, items, offer_cat]) =>
			this.applyServiceFilter(
				eventInfo,
				items.filter((item) => item.offer_cat === offer_cat),
			),
		),
		shareReplay(1),
	);
	package$ = combineLatest(this.packages$, this.packageIdxBS).pipe(
		map(([packages, idx]) => packages && packages[idx]),
	);
	levels$ = this.package$.pipe(
		map((pkg) => (pkg && pkg.children[0].offer_cat === "Package Level" ? pkg.children : null)),
	);
	level$ = combineLatest(this.levels$, this.levelIdxBS).pipe(map(([levels, idx]) => (levels ? levels[idx] : null)));
	groups$ = combineLatest(this.package$, this.level$).pipe(map(([pkg, level]) => pkg && (level || pkg).children));

	activeItemsInit$ = combineLatest(this.event.allEventItems$, this.menu.itemMap$).pipe(
		map(([results, itemMap]) => {
			const active = new Set<number>();
			const counts = new SMap<number, Set<number>>();

			for (const item of itemMap.values()) {
				const parent = itemMap.get(item.pkg_offerid);
				if (parent && (!parent.of_qty || parent.offer_cat === "Package Supergroup")) {
					const supergroup = itemMap.get(parent.pkg_offerid);
					if (!supergroup || supergroup.offer_cat !== "Package Supergroup") {
						active.add(item.offerid);
						counts.getOrInsertWith(item.pkg_offerid, () => new Set()).add(item.offerid);
					}
				}
			}

			const items = iter(results)
				.map((row: any) => tuple(row.offerid, row))
				.toMap();
			const pkg = results.find((x: any) => x.offer_cat === this.offer_cat);
			if (!pkg) {
				return { active, counts };
			}

			for (const row of results) {
				if (isChildOf(pkg.offerid, row, items)) {
					active.add(row.offerid);
					counts.getOrInsertWith(row.pkg_offerid, () => new Set()).add(row.offerid);
				}
			}

			return { active, counts };
		}),
		share(),
	);
	activeItems$ = this.activeItemsInit$.pipe(
		switchMap((activeItems) =>
			this.checkS.pipe(
				scan((acc, [item, checked]) => {
					if (checked) {
						acc.active.add(item.offerid);
						acc.counts.getOrInsertWith(item.pkg_offerid, () => new Set()).add(item.offerid);
					} else {
						acc.active.delete(item.offerid);
						acc.counts.getOrInsertWith(item.pkg_offerid, () => new Set()).delete(item.offerid);
					}
					return acc;
				}, activeItems),
				startWith(activeItems),
			),
		),
		shareReplay(1),
	);

	constructor(
		public event: EventService,
		private menu: CateringMenuService,
		private notification: ToastrService,
		private router: Router,
		private imagePipe: ImagePipe,
		route: ActivatedRoute,
	) {
		combineLatest(this.packages$, event.eventItemMap$, route.queryParams)
			.pipe(
				first(),
				tap(([pkgs, items, queryParams]) => {
					let idx =
						pkgs!.findIndex((pkg) => pkg.offerid === Number(queryParams.pkgid)) ||
						pkgs!.findIndex((pkg) => items.has(pkg.offerid));
					if (idx === -1) {
						idx = 0;
					}
					this.packageIdxBS.next(idx);
				}),
				switchMap(([_, items]) => this.levels$.pipe(map((lvl) => tuple(lvl, items)))),
				first(),
			)
			.subscribe(([lvls, items]) => {
				if (lvls) {
					let idx = lvls.findIndex((lvl: any) => items.has(lvl.offerid));
					if (idx === -1) {
						idx = 0;
					}
					this.levelIdxBS.next(idx);
				}
			});
		this.menu.items$.pipe(first()).subscribe((items) => {
			const itemMap = iter(items)
				.map((item: any) => tuple(item.offerid, { ...item, children: [] }))
				.toMap();

			for (const item of items) {
				if (!this.hoverImg.get(item.pkg_offerid)) {
					const parent = itemMap.get(item.pkg_offerid);
					this.hoverImg.set(item.pkg_offerid, item.image || parent.image || "s3static/18/generic-menu.jpg");
				}
			}
		});
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.offer_cat) {
			this.offer_catBS.next(this.offer_cat);
		}
	}

	offersAsCats(offers: any[]) {
		return offers.map((offer) => ({
			name: offer.offer,
			image: offer.image ? this.imagePipe.transform(offer.image, "m") : null,
		}));
	}

	applyServiceFilter(eventInfo: any, offers: any[]) {
		if (!eventInfo) {
			return null;
		}

		const filter = serviceFilter.get(eventInfo.food_serv_typeid);
		const ret = offers && offers.filter((offer) => !filter || !filter.has(offer.offerid));
		return ret;
	}

	submit() {
		this.savingBS.next(true);
		const submitChain$ = combineLatest(this.package$, this.level$, this.activeItems$, this.menu.itemMap$).pipe(
			first(),
			switchMap(([pkg, lvl, activeItems, itemMap]) => {
				let subOfferids = [];
				if (lvl) {
					subOfferids.push(lvl.offerid);
				}
				subOfferids = subOfferids.concat(
					iter(activeItems.active)
						.filter((x) => {
							const item = itemMap.get(x);
							return (
								item.offer_cat !== "Package Level" &&
								isChildOf(pkg.offerid, itemMap.get(x), itemMap, activeItems.active)
							);
						})
						.toArray(),
				);
				return this.event.addPackage(pkg.offerid, subOfferids);
			}),
			// tslint:disable-next-line: no-empty
			map(() => {}),
			tap(() => {
				this.savingBS.next(false);
				this.notification.success("Added package");
			}),
		);
		if (this.offer_cat === "Package") {
			submitChain$
				.pipe(
					switchMap(() => this.event.eventInfo$),
					first(),
					switchMap((eventInfo) => {
						this.popupChain = popupChains.get(eventInfo!.food_serv_typeid);
						this.popupIndexBS.next(0);
						return this.popupIndexBS;
					}),
					filter((i) => i! === this.popupChain.length),
					first(),
				)
				.subscribe(
					() => {
						this.router.navigateByUrl("/event-planner");
						this.submitted.next();
						this.popupIndexBS.next(null);
					},
					() => {
						this.notification.error("Failed to save");
						this.savingBS.next(false);
						this.popupIndexBS.next(null);
					},
				);
		} else {
			submitChain$.subscribe(
				() => this.submitted.next(),
				() => {
					this.notification.error("Failed to save");
					this.savingBS.next(false);
				},
			);
		}
	}

	submitCocktail() {
		if (this.offer_cat !== "Package") {
			return;
		}

		this.event.eventInfo$
			.pipe(
				first(),
				switchMap((eventInfo) => {
					this.popupChain = popupChains.get(eventInfo!.food_serv_typeid);
					this.popupIndexBS.next(0);
					return this.popupIndexBS;
				}),
				filter((i) => i! === this.popupChain.length),
				first(),
			)
			.subscribe(
				() => {
					this.router.navigateByUrl("/event-planner");
					this.submitted.next();
				},
				() => {
					this.notification.error("Failed to save");
				},
			);
	}

	countActive(activeItems: any, group: any) {
		return activeItems.counts
			.get(group.offerid)
			.map((x: any) => x.size)
			.unwrapOr(0);
	}

	checkDisabled(group: any, supergroup: any, activeItems: any, item: any) {
		if ((supergroup || group).offer_extra_price) {
			return false;
		}

		if (supergroup) {
			return (
				supergroup.of_qty &&
				!activeItems.active.has(item.offerid) &&
				iter(supergroup.children)
					.map((group) => this.countActive(activeItems, group))
					.sum() >= supergroup.of_qty
			);
		} else {
			return (
				!group.of_qty ||
				(!activeItems.active.has(item.offerid) && this.countActive(activeItems, group) >= group.of_qty)
			);
		}
	}

	private getCheckedFlat(offer: any, active: Set<number>): Iter<any> {
		const children = iter(offer.children).filter((x: any) => active.has(x.offerid));
		return children.chain(children.map((x) => this.getCheckedFlat(x, active)).flatten());
	}
}

function isChildOf(offerid: number, child: any, items: Map<number, any>, active?: Set<number>): boolean {
	while (child && child.pkg_offerid) {
		if (child.pkg_offerid === offerid) {
			return true;
		}
		if (active && !active.has(child.pkg_offerid)) {
			return false;
		}
		child = items.get(child.pkg_offerid);
	}
	return false;
}
